import { useDispatch, useSelector } from "react-redux";
import { CustomStripePaymentForm } from "../../forms";
import { forwardRef, useImperativeHandle, useState } from "react";
import { createSetupIntent, updateBookingPaymentMethod } from "../../util/api";
import css from './UpdateBookingPaymentMethodForm.module.scss';
import { loadPaymentMethod } from "../TransactionPage/TransactionPage.duck";

const UpdateBookingPaymentMethodForm = forwardRef((props, ref) => {
    const { transactionId, setPaymentMethodModalOpen } = props;

    const dispatch = useDispatch();
    const { currentUser } = useSelector(state => state.user);

    const [customClientSecret, setCustomClientSecret] = useState(null);

    useImperativeHandle(ref, () => ({
        fetchStripeClientSecret() {
            getCustomClientSecretAndSetForm();
        }
    }));

    const getCustomClientSecretAndSetForm = () => {
        if (!customClientSecret) {
            createSetupIntent()
                .then(setupIntentId => {
                    setCustomClientSecret(setupIntentId);
                    setCustomPaymentForm(true);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };

    const handleCustomPaymentSubmit = (stripeCustomerId, stripePaymentMethodId) => {
        updateBookingPaymentMethod({
            stripePaymentMethodId,
            transactionId
        }).then(() => {
            dispatch(loadPaymentMethod({ id: transactionId }))
                .then(() => {
                    // setCustomClientSecret(null);
                    // setPaymentMethodModalOpen(false);
                    location.reload();
                });
        }).catch(error => {
            console.error(error);
        });
    };

    return (
        <>
            <h2>Update payment method</h2>
            <div className={css.paymentFormContainer}>
                {customClientSecret && (
                    <CustomStripePaymentForm
                        clientSecret={customClientSecret}
                        ctaButtonTxt="Save payment method"
                        handleCustomPaymentSubmit={handleCustomPaymentSubmit}
                        currentUserEmail={currentUser.attributes.email}
                        showInitialMessageInput={false}
                    />
                )}
            </div>
        </>
    )
});

export default UpdateBookingPaymentMethodForm;