import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import { Button } from '../../components';
import { addTaxInfo, saveTaxInfo } from '../../containers/TaxSettingsPage/TaxSettingsPage.duck';
import { TAX_STATUS_DECLINED, TAX_STATUS_PENDING, TAX_STATUS_VERIFIED } from '../../util/types';

import css from './TaxInfoForm.module.scss';

const TaxInfoForm = props => {
    const { setTaxInfoModalOpen } = props;

    const dispatch = useDispatch();
    const state = useSelector(state => state.TaxSettingsPage);
    const { taxInfo, saveTaxInfoInProgress } = state;

    const [taxId, setTaxId] = useState(null);
    const [legalBusinessName, setLegalBusinessName] = useState(null);

    useEffect(() => {
        if (taxInfo) {
            setTaxId(taxInfo.taxId);
            setLegalBusinessName(taxInfo.legalBusinessName);
        }

        if (!saveTaxInfoInProgress) {
            if (setTaxInfoModalOpen) {
                setTaxInfoModalOpen(false);
            }
        }
    }, [taxInfo, saveTaxInfoInProgress]);

    const onHandleSubmit = () => {
        if (taxId && legalBusinessName) {
            const taxInfoId = taxInfo?.id;
            if (taxInfoId) {
                dispatch(saveTaxInfo({
                    taxId,
                    legalBusinessName,
                    taxInfoId
                }));
            } else {
                dispatch(addTaxInfo({
                    taxId,
                    legalBusinessName,
                }));
            }
        }
    };

    const taxVerificationStatusText = taxInfo ? (
        <h6 className={css.taxVerificationStatusText}>
            {taxInfo.status === TAX_STATUS_VERIFIED && (
                <span className={css.verified}>
                    <FormattedMessage id="TaxSettingsPage.verifiedTaxInfoMessage" />
                </span>
            )}
            {taxInfo.status === TAX_STATUS_PENDING && (
                <span className={css.pending}>
                    <FormattedMessage id="TaxSettingsPage.pendingTaxInfoMessage" />
                </span>
            )}
            {taxInfo.status === TAX_STATUS_DECLINED && (
                <span className={css.declined}>
                    <FormattedMessage id="TaxSettingsPage.declinedTaxInfoMessage" />
                </span>
            )}
        </h6>
    ) : (
        null
    );

    return (
        <div className={css.content}>
            <h1 className={css.title}>
                <FormattedMessage id="TaxSettingsPage.heading" />
            </h1>
            {taxVerificationStatusText}
            <div className={css.rowUnsetMarginLR}>
                <div className={css.col12}>
                    <label><FormattedMessage id="TaxSettingsPage.taxIdLabel" /></label>
                    {taxInfo?.status === TAX_STATUS_VERIFIED ? (
                        <div className={css.disabledInputPlaceholder}>
                            {taxInfo.taxId}
                        </div>
                    ) : (
                        <input type="text" value={taxId} onChange={(e) => setTaxId(e.target.value)} />
                    )}
                </div>
            </div>
            <br />
            <div className={css.rowUnsetMarginLR}>
                <div className={css.col12}>
                    <label><FormattedMessage id="TaxSettingsPage.legalBusinessNameLabel" /></label>
                    {taxInfo?.status === TAX_STATUS_VERIFIED ? (
                        <div className={css.disabledInputPlaceholder}>
                            {taxInfo.legalBusinessName}
                        </div>
                    ) : (
                        <input type="text" value={legalBusinessName} onChange={(e) => setLegalBusinessName(e.target.value)} />
                    )}
                </div>
            </div>
            <br />
            <div className={css.rowUnsetMarginLR}>
                <div className={css.col6}></div>
                <div className={css.col6}>
                    <Button className={css.taxInfoSubmit}
                        inProgress={saveTaxInfoInProgress}
                        onClick={() => onHandleSubmit()}
                        type="button">
                        <FormattedMessage id="TaxSettingsPage.saveButtonText" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default TaxInfoForm;