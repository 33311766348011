import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingPoliciesForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPoliciesPanel.module.css';
const { Money } = sdkTypes;

const EditListingPoliciesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className, css.panel);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPoliciesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPoliciesPanel.createListingTitle" />
  );

  const installationFeeAmount = publicData.installationFee ?? 0;
  const installationFee = new Money(installationFeeAmount, config.currency);

  const {
    dimensionsOfArtworkHeight, dimensionsOfArtworkWidth, dimensionsOfArtworkUnit, supported_files_types,
  } = publicData;

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingPoliciesForm
        className={css.form}
        publicData={publicData}
        initialValues={{
          rules: publicData.rules,
          installationFee,
          installationWaived: publicData.installationWaived,
          dimensionsOfArtworkHeight, dimensionsOfArtworkWidth, dimensionsOfArtworkUnit,
          supported_files_types
        }}
        onSubmit={values => {
          const {
            rules = '', installationFee, installationWaived,
            dimensionsOfArtworkHeight, dimensionsOfArtworkWidth, dimensionsOfArtworkUnit,
            supported_files_types = []
          } = values;

          const updateValues = {
            publicData: {
              rules,
              //we save installationFee like this because ST allows price objects only in the price field,
              //which is reserved for the listing main price
              installationFee: installationFee.amount,
              installationWaived,
              dimensionsOfArtworkHeight, dimensionsOfArtworkWidth, dimensionsOfArtworkUnit,
              supported_files_types
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPoliciesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPoliciesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPoliciesPanel;
