import moment from "moment";

export default class DateTimeUtil {
    static formatDate(date, format = 'llll') {
        if (date) {
            return moment(date).format(format);
        } else {
            return '';
        }
    }

    static generateAvailabilityRange(startDate, interval, unitsInDate) {
        const momentBuilder = this.convertToUTCNoonDate(startDate)
        return {
            start: startDate,
            end: momentBuilder.add(interval, unitsInDate).toDate()
        }
    }

    static convertToUTCMidnightDate(date) {
        return moment
            .utc(date)
            .startOf('day')
    }

    static convertToUTCNoonDate(date) {
        const dateUtcMidnight = this.convertToUTCMidnightDate(date)
        return dateUtcMidnight.add(12, 'hours')
    }

    static dateAdd(date, interval, duration) {
        const momentBuilder = moment(date);
        return momentBuilder.add(interval, duration);
    }

    static dateSubtract(date, interval, duration) {
        const momentBuilder = moment(date);
        return momentBuilder.subtract(interval, duration);
    }

    static getDateDiff(compare1, compare2, duration) {
        const momentBuilder = moment.duration(moment(compare1).diff(moment(compare2)));
        switch (duration) {
            case 'month':
                return momentBuilder.asMonths();
            case 'hours':
                return momentBuilder.asHours();
            case 'days':
            default:
                return momentBuilder.asDays();
        }
    }
}