import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_REVERSED, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemReversedMaybe = props => {
  const { transaction, intl } = props;

  const lineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_REVERSED
  );

  return lineItem ? (
    <div className={css.lineItem}>
      <div className={css.reversedLabel}>
        <FormattedMessage id="LineItemReversedMaybe.label" />
      </div>
      <div className={css.reversedPrice}>{formatMoney(intl, lineItem.lineTotal)}</div>
    </div>
  ) : null;
};

LineItemReversedMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemReversedMaybe;
