import { FormattedMessage, injectIntl } from 'react-intl';
import css from './TransactionPanel.module.scss';
import { useRef, useState } from 'react';
import Modal from '../Modal/Modal';
import RefundBreakdownMaybe from '../RefundBreakdownMaybe/RefundBreakdownMaybe';

const CancelActionButtonMaybe = injectIntl(props => {
    const { onManageDisableScrolling, transactionId, intl, isProvider, booking } = props;

    const refundBreakdownMaybeRef = useRef(null);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);

    return (
        <div className={css.cancelContainer}>
            <div className={css.rowUnsetMarginLR}>
                <div className={css.col6}></div>
                <div className={css.col6}>
                    <button
                        onClick={() => {
                            refundBreakdownMaybeRef.current.fetchRefundAmounts();
                            setCancelModalOpen(true);
                        }}
                        className={css.cancelButton}
                        type="button">
                        <FormattedMessage id="TransactionPanel.cancelButton" />
                    </button>
                </div>
            </div>
            <Modal
                id="TransactionPanel.cancelModal"
                isOpen={cancelModalOpen}
                onClose={() => {
                    setCancelModalOpen(false);
                }}
                usePortal={true}
                onManageDisableScrolling={onManageDisableScrolling}>
                <RefundBreakdownMaybe
                    setCancelModalOpen={setCancelModalOpen}
                    transactionId={transactionId}
                    ref={refundBreakdownMaybeRef}
                    intl={intl}
                    isProvider={isProvider}
                    booking={booking}
                />
            </Modal>
        </div>
    );
});

export default CancelActionButtonMaybe;