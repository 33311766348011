import { createCustomOffer, getCustomOfferTrxLineItems } from '../../util/api';
import css from './CustomOfferForm.module.scss';
import { useEffect, useState } from 'react';
import Button from '../Button/Button';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { bool, func, object } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import DateRangeInput from '../FieldDateRangeInput/DateRangeInput';
import { LINE_ITEM_DAY, LINE_ITEM_TAX_ON_BOOKING_DEDUCTED_FROM_PROVIDER_PAYOUT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { debounce } from "lodash";
import IconSpinner from '../IconSpinner/IconSpinner';
import CustomOfferBreakdownMaybe, { calculateEstimatedTransaction } from '../../forms/BookingDatesForm/CustomOfferBreakdownMaybe';
import { useDispatch } from 'react-redux';
import { resetPaymentLineItems } from '../../ducks/paymentLineItems.duck';
import { getBookingQtyAtleast1Month, residualInterval } from '../BookingBreakdown/subscription/utils';
import StripeExpressStatusBox from '../StripeExpressStatusBox/StripeExpressStatusBox';
import { FieldRadioButton } from '..';
import { PaymentOptionEnum } from '../../enums/payment-option.enum';

const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

const MIN_EXPIRY_DAYS = 2;
const MAX_EXPIRY_DAYS = 30;

const MIN_NUMBER_VALUE = 1;

const DEBOUNCE_TIMEOUT = 500;

const filterListing = (listing) => {
    // delete listing.attributes.price;
    // delete listing.attributes.publicData.subscriptionPrice;
    delete listing.attributes.publicData.yearTerm1Discount;
    delete listing.attributes.publicData.yearTerm2Discount;
    delete listing.attributes.publicData.yearTerm3Discount;
    delete listing.attributes.publicData.yearTerm4Discount;
    delete listing.attributes.publicData.installationFee;
    delete listing.attributes.publicData.installationWaived;
    return listing;
};

const CustomOfferForm = injectIntl(props => {
    const {
        intl,
        listing,
        transactionId,
        onCustomOfferCreated,
        isProvider = true,
        unitType = LINE_ITEM_DAY,
    } = props;

    const ensuredListing = filterListing(listing);

    const dispatch = useDispatch();

    const [submitted, setSubmitted] = useState(false);

    const [selectedPaymentOption, setSelectedPaymentOption] = useState(PaymentOptionEnum.Monthly);
    const [autoDayPrice, setAutoDayPrice] = useState();
    const [manualDayPrice, setManualDayPrice] = useState();
    const [subscriptionPrice, setSubscriptionPrice] = useState();
    const [installationFee, setInstallationFee] = useState();
    const [seats, setSeats] = useState(null);

    const [subscriptionPayments, setSubscriptionPayments] = useState(null);
    const [subscriptionTotal, setSubscriptionTotal] = useState(null);
    const [lineItems, setLineItems] = useState(null);
    const [payinTotal, setPayinTotal] = useState(null);
    const [payoutTotal, setPayoutTotal] = useState(null);

    const [autoDayPriceEnabled, setAutoDayPriceEnabled] = useState(true);
    const [fetchInProgress, setFetchInProgress] = useState(false);

    const [description, setDescription] = useState();
    const [expiryValue, setExpiryValue] = useState('');
    const [expiryDate, setExpiryDate] = useState();
    const [bookingStartDate, setBookingStartDate] = useState();
    const [bookingEndDate, setBookingEndDate] = useState();
    const [inputDate, setInputDate] = useState();
    const [bookingQtyAtleast1Month, setBookingQtyAtleast1Month] = useState()

    const [transaction, setTransaction] = useState(null);
    const [booking, setBooking] = useState(null);

    const [createInProgress, setCreateInProgress] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    useEffect(() => {
        if (bookingStartDate && bookingEndDate) {
            onFetchLineItems();
        }
        if (subscriptionPrice) {
            listing.attributes.publicData.subscriptionPrice = subscriptionPrice;
            if (autoDayPriceEnabled) {
                const dailyPriceAmount = subscriptionPrice / 31;
                const finalDailyPriceAmount = Number(dailyPriceAmount.toFixed(2));
                setAutoDayPrice(finalDailyPriceAmount);
            } else {
                setManualDayPrice(manualDayPrice);
            }
        }
        if (autoDayPrice) {
            listing.attributes.price = new Money(autoDayPrice, config.currency);
            if (subscriptionPrice) {
                onFetchLineItems();
            }
        }
        if (manualDayPrice) {
            listing.attributes.price = new Money(manualDayPrice, config.currency);
            if (subscriptionPrice) {
                onFetchLineItems();
            }
        }
        if (autoDayPriceEnabled) {
            if (autoDayPrice) {
                setAutoDayPrice(autoDayPrice);
            }
        } else {
            if (manualDayPrice) {
                setManualDayPrice(manualDayPrice);
            }
        }
        if (installationFee) {
            listing.attributes.publicData.installationFee = installationFee;
            onFetchLineItems();
        }
        if (seats) {
            onFetchLineItems();
        }
        if (selectedPaymentOption) {
            onFetchLineItems()
        }
    }, [bookingStartDate, bookingEndDate, subscriptionPrice, autoDayPrice, manualDayPrice, installationFee, seats, autoDayPriceEnabled, selectedPaymentOption]);

    const onHandleSubmit = () => {
        setSubmitted(true);
        const dayPrice = getChosenDayPrice();
        if (bookingStartDate && bookingEndDate && subscriptionPrice && dayPrice && seats && description && expiryDate) {
            setSubmitError(null);
            setCreateInProgress(true);
            const lineItemsFiltered = lineItems.filter(item => item.code !== LINE_ITEM_TAX_ON_BOOKING_DEDUCTED_FROM_PROVIDER_PAYOUT);
            const customOffer = {
                subscriptionPrice,
                dayPrice,
                installationFee: installationFee ? installationFee : 0,
                seats,
                description,
                expiry: expiryDate,
                bookingStartDate,
                bookingEndDate,
                sharetribeListingUUID: ensuredListing.id.uuid,
                transaction: transaction ? JSON.stringify(transaction) : null,
                lineItems: lineItemsFiltered ? JSON.stringify(lineItemsFiltered) : null,
                subscriptionPayments: subscriptionPayments ? JSON.stringify(subscriptionPayments) : null,
                subscriptionTotal: subscriptionTotal ? JSON.stringify(subscriptionTotal) : null,
                payinTotal: payinTotal ? JSON.stringify(payinTotal) : null,
                sharetribeTransactionUUID: transactionId.uuid,
            };
            createCustomOffer({ transactionId: transactionId.uuid, customOffer })
                .then(() => {
                    setSubmitted(false);
                    setCreateInProgress(false);
                    dispatch(resetPaymentLineItems());
                    onCustomOfferCreated();
                }).catch(error => {
                    console.error(error);
                    setSubmitted(false);
                    setCreateInProgress(false);
                    if (error?.message === 'stripe_account_not_found') {
                        setSubmitError(`Error: Please connect your Stripe account. You must be connected to Stripe to create a Custom Offer.`);
                    } else if (error?.message === 'stripe_payouts_disabled') {
                        setSubmitError(`Error: In order for you to receive payments you need to add few more details to your Stripe account to verify your account.`);
                    } else {
                        setSubmitError(`Error: there was an error creating your custom offer - ${error?.message}`);
                    }
                });
        } else {
            setSubmitError('Please ensure all values are set.');
        }
    };

    const onFetchLineItems = () => {
        if (bookingStartDate && bookingEndDate) {
            setFetchInProgress(true);
            setBooking(null);
            setTransaction(null);
            setSubmitError(null);
            setBookingQtyAtleast1Month(getBookingQtyAtleast1Month(bookingStartDate, bookingEndDate))
            const subscriptionLineItems = residualInterval(bookingStartDate, bookingEndDate);
            const residualMonth = subscriptionLineItems?.residualMonth;
            const subscriptionFirstMonth = subscriptionLineItems?.subscriptionFirstMonth;
            const monthCount = subscriptionLineItems?.monthCount;
            const dayPrice = getChosenDayPrice();
            const newBookingData = {
                isCustomOffer: true,
                start: bookingStartDate,
                end: bookingEndDate,
                startDate: bookingStartDate,
                endDate: bookingEndDate,
                displayStart: bookingStartDate,
                displayEnd: bookingEndDate,
                seats,
                residualMonth,
                subscriptionFirstMonth,
                customOfferDayPrice: dayPrice,
                customOfferInstallationFee: installationFee,
                selectedPaymentOption,
                monthCount
            };
            getCustomOfferTrxLineItems({
                listing: ensuredListing, bookingData: newBookingData
            }).then(trx => {
                setFetchInProgress(false);
                console.log(`getCustomOfferTrxLineItems res`, trx);
                setBooking({ attributes: newBookingData });
                const estimatedTrx = calculateEstimatedTransaction(
                    newBookingData.startDate, newBookingData.endDate, trx.attributes.lineItems, trx.payoutTotal
                );
                setTransaction(estimatedTrx);
                setLineItems(estimatedTrx.attributes.lineItems);
                setPayinTotal(estimatedTrx.attributes.payinTotal);
                setPayoutTotal(estimatedTrx.attributes.payoutTotal);
            }).catch(error => {
                setFetchInProgress(false);
                console.error(error);
                setSubmitError(`Error: there was an error fetching amount line items - ${error?.message}`);
            });
        }
    };

    const onSetSubscriptionPayments = (subscriptionPayments, subscriptionTotal) => {
        setSubscriptionPayments(subscriptionPayments);
        setSubscriptionTotal(subscriptionTotal);
    };

    const onSetSelectedPaymentOption = debounce((paymentOption) => {
        onSetSubscriptionPayments(null, null);
        setSelectedPaymentOption(paymentOption);
    }, DEBOUNCE_TIMEOUT);

    const onSetSubscriptionPrice = debounce((price) => {
        const finalPrice = price * 100;
        setSubscriptionPrice(finalPrice);
    }, DEBOUNCE_TIMEOUT);

    const onSetAutoDayPrice = (isAuto) => {
        setAutoDayPriceEnabled(isAuto);
    };

    const getChosenDayPrice = () => {
        const dayPrice = autoDayPriceEnabled ? autoDayPrice : manualDayPrice;
        return dayPrice;
    }

    const onSetManualPrice = debounce((price) => {
        const finalPrice = price * 100;
        setAutoDayPriceEnabled(false);
        setManualDayPrice(finalPrice);
    }, DEBOUNCE_TIMEOUT);

    const onSetInstallationFee = debounce((fee) => {
        const finalFee = fee * 100;
        setInstallationFee(finalFee);
    }, DEBOUNCE_TIMEOUT);

    const onSetSeats = debounce((seats) => {
        setSeats(parseInt(seats));
    }, DEBOUNCE_TIMEOUT);

    const onChangeExpiry = (value) => {
        if (value !== '') {
            const date = moment.utc(new Date()).add(value, 'days');
            setExpiryValue(value);
            setExpiryDate(date.toDate());
        }
    };

    const getExpiryValues = () => {
        const days = new Array();
        for (let i = MIN_EXPIRY_DAYS; i < MAX_EXPIRY_DAYS; i++) {
            days.push(i);
        }
        return days;
    };

    const onChangeDate = values => {
        if (values) {
            setInputDate(values);
            if (values.startDate) {
                setBookingStartDate(values.startDate);
            }
            if (values.endDate) {
                setBookingEndDate(values.endDate);
            }
        }
    };

    const onBlur = value => {
        // console.log(`onBlur value`, value)
    };

    const onFocus = value => {
        // console.log(`onFocus value`, value)
    };

    return (
        <>
            <h2><FormattedMessage id="CustomOfferForm.title" /></h2>
            <StripeExpressStatusBox transactionId={transactionId} isCustomOffer={true} />
            <form className={css.customOfferForm}>
                <div className={css.rowUnsetMarginLR}>
                    <div className={css.col12}>
                        <label>
                            <FormattedMessage id="CustomOfferForm.dateRangeTitle" />
                        </label>
                        <DateRangeInput
                            onChange={onChangeDate}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            value={inputDate}
                        />
                        {submitted && !bookingStartDate && !bookingEndDate &&
                            <span className={css.errorRequiredField}>
                                required
                            </span>
                        }
                    </div>
                </div>
                {bookingQtyAtleast1Month &&
                    <div className={css.col12}>
                        <div>
                            <label><FormattedMessage id="CustomOfferForm.paymentOption.label" /></label>
                            <FieldRadioButton
                                id="subscriptionOption"
                                rootClassName={css.paymentOptionFields}
                                name="selectedPaymentOption"
                                label={intl.formatMessage({ id: "CustomOfferForm.monthlyOption" })}
                                value={PaymentOptionEnum.Monthly}
                                onChange={(e) => onSetSelectedPaymentOption(e.target.value)}
                                checked={selectedPaymentOption === PaymentOptionEnum.Monthly}
                            />
                            <FieldRadioButton
                                id="fullPaymentOption"
                                rootClassName={css.paymentOptionFields}
                                name="selectedPaymentOption"
                                label={intl.formatMessage({ id: "CustomOfferForm.fullPaymentOption" })}
                                value={PaymentOptionEnum.FullPayment}
                                onChange={(e) => onSetSelectedPaymentOption(e.target.value)}
                                checked={selectedPaymentOption === PaymentOptionEnum.FullPayment}
                            />
                        </div>
                    </div>
                }
                <div className={css.col12}>
                    <label>
                        <FormattedMessage id="CustomOfferForm.subscriptionPrice.label" />
                    </label>
                    <input type="number"
                        min={MIN_NUMBER_VALUE}
                        onWheel={(e) => e.target.blur()}
                        placeholder={intl.formatMessage({ id: 'CustomOfferForm.subscriptionPrice.placeholder' })}
                        onChange={(e) => onSetSubscriptionPrice(e.target.value)}
                    />
                    {submitted && !subscriptionPrice &&
                        <span className={css.errorRequiredField}>
                            required
                        </span>
                    }
                </div>
                {(autoDayPrice && autoDayPriceEnabled) &&
                    <div className={css.col6}>
                        <label>
                            <FormattedMessage id="CustomOfferForm.pricePerDayAuto" />
                        </label>
                        <span>{formatMoney(intl, new Money(autoDayPrice, 'CAD'))}</span>
                        <br />
                        <a onClick={() => onSetAutoDayPrice(false)}>
                            <small>
                                <FormattedMessage id="CustomOfferForm.switchManualPrice" />
                            </small>
                        </a>
                    </div>
                }
                {!autoDayPriceEnabled &&
                    <div className={css.col6}>
                        <label>
                            <FormattedMessage id="CustomOfferForm.pricePerDayManual" />
                        </label>
                        <input type="number"
                            min={MIN_NUMBER_VALUE}
                            onWheel={(e) => e.target.blur()}
                            placeholder={intl.formatMessage({ id: 'CustomOfferForm.dayPrice.placeholder' })}
                            onChange={(e) => onSetManualPrice(e.target.value)}
                        />
                        {submitted && !manualDayPrice &&
                            <>
                                <span className={css.errorRequiredField}>
                                    required
                                </span>
                                <br />
                            </>
                        }
                        <a onClick={() => onSetAutoDayPrice(true)}>
                            <small>
                                <FormattedMessage id="CustomOfferForm.switchAutoPrice" />
                            </small>
                        </a>
                    </div>
                }
                <div className={css.col12}>
                    <label><FormattedMessage id="CustomOfferForm.installationFee.label" /></label>
                    <input type="number"
                        min={MIN_NUMBER_VALUE}
                        onWheel={(e) => e.target.blur()}
                        placeholder={intl.formatMessage({ id: 'CustomOfferForm.installationFee.placeholder' })}
                        onChange={(e) => onSetInstallationFee(e.target.value)}
                    />
                </div>
                <div className={css.col12}>
                    <label><FormattedMessage id="CustomOfferForm.seats.label" /></label>
                    <input type="number"
                        min={MIN_NUMBER_VALUE}
                        onWheel={(e) => e.target.blur()}
                        placeholder={intl.formatMessage({ id: 'CustomOfferForm.seats.placeholder' })}
                        onChange={(e) => onSetSeats(e.target.value)}
                    />
                    {submitted && !seats &&
                        <span className={css.errorRequiredField}>
                            required
                        </span>
                    }
                </div>
                <div className={css.col12}>
                    <div className={css.priceBreakdownContainer}>
                        <hr className={css.totalDivider} />
                        {transaction &&
                            <>
                                {fetchInProgress &&
                                    <IconSpinner className={css.iconSpinner} />
                                }
                                {(!fetchInProgress && booking && transaction && onSetSubscriptionPayments) &&
                                    <CustomOfferBreakdownMaybe
                                        bookingData={{
                                            ...booking.attributes,
                                            unitType
                                        }}
                                        transaction={transaction}
                                        lineItems={transaction.attributes.lineItems}
                                        listing={ensuredListing}
                                        setLineItemsSubscription={onSetSubscriptionPayments}
                                        isProvider={isProvider}
                                        userRole={'provider'}
                                        isCheckout={true}
                                    />
                                }
                            </>
                        }
                        <hr className={css.totalDivider} />
                    </div>
                </div>
                <div className={css.col12}>
                    <label><FormattedMessage id="CustomOfferForm.description.label" /></label>
                    <textarea
                        placeholder={intl.formatMessage({ id: 'CustomOfferForm.description.placeholder' })}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    {submitted && !description &&
                        <span className={css.errorRequiredField}>
                            required
                        </span>
                    }
                </div>
                <div className={css.col12}>
                    <label><FormattedMessage id="CustomOfferForm.expiry" /></label>
                    <select value={expiryValue} onChange={e => onChangeExpiry(e.target.value)}>
                        <option disabled={!expiryValue} value={''}>
                            {intl.formatMessage({ id: 'CustomOfferForm.selectDaysOption' })}
                        </option>
                        {getExpiryValues().map(day => (
                            <option value={day}>
                                {intl.formatMessage({ id: 'CustomOfferForm.expiry.option' }, { day })}
                            </option>
                        ))}
                    </select>
                    {submitted && !expiryValue && !expiryDate &&
                        <span className={css.errorRequiredField}>
                            required
                        </span>
                    }
                    {expiryDate &&
                        <p>
                            <strong>
                                <FormattedMessage id="CustomOfferForm.expiryDate" />
                            </strong> {moment(expiryDate).format('llll')}
                        </p>
                    }
                </div>
                <br />
                <div className={css.col12}>
                    <Button className={css.submitButton}
                        inProgress={createInProgress}
                        disabled={fetchInProgress}
                        onClick={() => onHandleSubmit()}
                        type="button">
                        <FormattedMessage id="CustomOfferForm.sendOffer" />
                    </Button>
                </div>
                {submitError &&
                    <div className={css.col12}>
                        <p className={css.error}>{submitError}</p>
                    </div>
                }
            </form>
        </>
    );
});

CustomOfferForm.defaultProps = {
    listing: null,
    isProvider: false,
    transactionId: null,
    onCustomOfferCreated: null,
};

CustomOfferForm.propTypes = {
    listing: object.isRequired,
    isProvider: bool.isRequired,
    transactionId: propTypes.uuid.isRequired,
    onCustomOfferCreated: func.isRequired,
    intl: intlShape.isRequired,
};

export default CustomOfferForm;