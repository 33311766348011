import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
  txIsSubscribed,
  txIsRequestSubscribe,
  txIsAcceptedSubscription,
  txIsDeclinedSubscription,
  txIsAutoRenewal,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_DISCOUNT } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
  SecondaryButton,
  FieldCheckbox,
  Modal,
  UpdatePaymentMethodMaybe,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
  HEADING_SUBSCRIBED,
  HEADING_REQUEST_SUBSCRIBE,
  HEADING_DECLINED_SUBSCRIPTION,
  HEADING_ACCEPTED_SUBSCRIPTION,
} from './PanelHeading';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './TransactionPanel.module.scss';
import {
  acceptAutoRenewal,
  acceptBooking,
  declineAutoRenewal,
  declineBooking,
} from '../../util/api';
import {
  getBookingQtyAtleast1Month,
  getSubscriptionIntervals,
  getTotalForDueLater,
} from '../BookingBreakdown/subscription/utils';
import { addDate, daysBetween, subtractDate, toMomentDate } from '../../util/dates';
import PaymentScheduleBreakdown from '../PaymentScheduleBreakdown/PaymentScheduleBreakdown';
import CancelActionButtonMaybe from './CancelActionButtonMaybe';
import StripeExpressStatusBox from '../StripeExpressStatusBox/StripeExpressStatusBox';
const { UUID, Money } = sdkTypes;

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      autoRenewalChangeArtwork: false,
      autoRenewalModalOpen: false,
      autoRenewalSubscriptionPayments: null,
      autoRenewalSubscriptionTotal: null,

      trxPanelAcceptInProgress: false,
      trxPanelDeclineInProgress: false,

      showStripeExpressModal: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.onSetAutoRenewalChangeArtwork = this.onSetAutoRenewalChangeArtwork.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  onAcceptSaleCustomPayment(transactionId) {
    this.setState({ trxPanelAcceptInProgress: true });
    acceptBooking({ transactionId: transactionId.uuid })
      .then(() => {
        location.reload();
      })
      .catch(error => {
        this.setState({ trxPanelAcceptInProgress: false });
        if (error?.message === 'stripe_account_not_found') {
          this.setState({ showStripeExpressModal: true });
        } else if (error?.message === 'stripe_payouts_disabled') {
          this.setState({ showStripeExpressModal: true });
        }
        console.log(error);
      });
  }

  onDeclineSaleCustomPayment(transactionId) {
    this.setState({ trxPanelDeclineInProgress: true });
    declineBooking({ transactionId: transactionId.uuid })
      .then(() => {
        location.reload();
      })
      .catch(e => {
        this.setState({ trxPanelDeclineInProgress: false });
        console.log(e);
      });
  }

  onCalculateAutoRenewalPayments(currentTransaction, submitAutoRenewal = false) {
    const { booking, listing } = currentTransaction;
    const lineItems = currentTransaction.attributes?.lineItems;
    const discountLineItem = lineItems.find(item => item.code === LINE_ITEM_DISCOUNT);
    const { displayEnd } = booking.attributes;
    const { autoRenewalChangeArtwork } = this.state;
    const subscriptionDiscountPercentage = discountLineItem?.percentage ? discountLineItem.percentage : null;

    let subscriptionInstallationFee = null;
    if (autoRenewalChangeArtwork) {
      const installationFee = listing.attributes.publicData?.installationFee;
      if (installationFee) {
        const numberOfSeats = booking.attributes.seats;
        subscriptionInstallationFee = installationFee * numberOfSeats;
      }
    }

    const start = subtractDate(toMomentDate(displayEnd), 1, 'months');
    const end = addDate(toMomentDate(displayEnd), 1, 'years');
    const bookingQtyAtleast1Month = getBookingQtyAtleast1Month(start, new Date(end))

    const subscriptionPayments = getSubscriptionIntervals(
      start,
      end,
      currentTransaction,
      bookingQtyAtleast1Month,
      listing,
      subscriptionInstallationFee,
      subscriptionDiscountPercentage
    );

    this.setState({ autoRenewalSubscriptionPayments: subscriptionPayments }, () => {
      const subscriptionTotal = getTotalForDueLater(
        currentTransaction,
        bookingQtyAtleast1Month,
        listing,
        subscriptionPayments,
        subscriptionInstallationFee,
        subscriptionDiscountPercentage
      );
      this.setState({ autoRenewalSubscriptionTotal: subscriptionTotal }, () => {
        if (submitAutoRenewal) {
          this.setState({ trxPanelAcceptInProgress: true });
          acceptAutoRenewal({
            transactionId: currentTransaction.id.uuid,
            subscriptionPayments: this.state.autoRenewalSubscriptionPayments,
            subscriptionTotal: this.state.autoRenewalSubscriptionTotal,
          })
            .then(() => {
              location.reload();
            })
            .catch(e => {
              this.setState({ trxPanelAcceptInProgress: false });
              console.log(e);
            });
        }
      });
    });
  }

  onAcceptAutoRenewal(currentTransaction) {
    this.onCalculateAutoRenewalPayments(currentTransaction, true);
  }

  onDeclineAutoRenewal(transactionId) {
    this.setState({ trxPanelDeclineInProgress: true });
    declineAutoRenewal({ transactionId: transactionId.uuid })
      .then(() => {
        location.reload();
      })
      .catch(e => {
        this.setState({ trxPanelDeclineInProgress: false });
        console.log(e);
      });
  }

  onSetAutoRenewalChangeArtwork(value) {
    this.setState({
      autoRenewalChangeArtwork: value,
    });
  }

  onViewAutoRenewalSchedule(currentTransaction) {
    this.onCalculateAutoRenewalPayments(currentTransaction);
    this.setState({ autoRenewalModalOpen: true });
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      onAcceptSubscription,
      onDeclineSubscription,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      acceptSubscriptionInProgress,
      declineSubscriptionInProgress,
      acceptSubscriptionError,
      declineSubscriptionError,
      onSubmitBookingRequest,
      timeSlots,
      fetchTimeSlotsError,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      cancelAllowed,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const isCustomPaymentBooking = currentTransaction.attributes.isCustomPaymentBooking;
    const withinAutoRenewalPeriod = currentTransaction.attributes.withinAutoRenewalPeriod;
    const IsCustomerAndWithinAutoRenewal = isCustomer && withinAutoRenewalPeriod;

    const installationFee = currentListing?.attributes?.publicData?.installationFee;

    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
            return transition.attributes.name;
          })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showSaleButtons: IsCustomerAndWithinAutoRenewal,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsSubscribed(tx)) {
        return {
          headingState: HEADING_SUBSCRIBED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsRequestSubscribe(tx)) {
        return {
          headingState: HEADING_REQUEST_SUBSCRIBE,
          showDetailCardHeadings: isCustomer,
          showSubscriptionButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAcceptedSubscription(tx)) {
        return {
          headingState: HEADING_ACCEPTED_SUBSCRIPTION,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsDeclinedSubscription(tx)) {
        return {
          headingState: HEADING_DECLINED_SUBSCRIPTION,
          showDetailCardHeadings: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
        ? 'TransactionPanel.perDay'
        : 'TransactionPanel.perUnit';

    const metadata = currentTransaction.attributes.metadata;

    const subscriptionStartDate = metadata?.startDate
      ? new Date(metadata.startDate).toLocaleDateString()
      : null;

    const price = currentListing && currentListing.attributes.price;
    const subscriptionAmount =
      currentListing && currentListing.attributes.publicData.subscriptionPrice;
    const subscriptionPrice = subscriptionAmount && new Money(subscriptionAmount, config.currency);
    const bookingSubTitle = subscriptionStartDate
      ? subscriptionStartDate
        ? `${formatMoney(intl, subscriptionPrice)} ${intl.formatMessage({
          id: 'TransactionPanel.perMonth',
        })}`
        : ''
      : price
        ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
        : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const autoRenewalMessageBox = (
      <>
        {IsCustomerAndWithinAutoRenewal && (
          <div className={css.autoRenewalMessageBox}>
            <div className={css.rowUnsetMarginLR}>
              <div className={css.autoRenewalMessageText}>
                <p>
                  <FormattedMessage id={'TransactionPanel.autoRenewalMessage'} />
                </p>
              </div>
            </div>
            {installationFee && (
              <>
                <label className={css.changeMyArtworkLabel}>
                  <input
                    className={css.changeMyArtworkInput}
                    type="checkbox"
                    name="autoRenewalChangeArtwork"
                    onChange={e => this.onSetAutoRenewalChangeArtwork(e.target.checked)}
                  />
                  <FormattedMessage id={'TransactionPanel.changeMyArtworkLabel'} />
                </label>
                <p className={css.installationFeeChargeMessage}>
                  <FormattedMessage
                    id={'TransactionPanel.installationFeeChargeMessage'}
                    values={{
                      installationFee: formatMoney(
                        intl,
                        new Money(installationFee, config.currency)
                      ),
                    }}
                  />
                </p>
              </>
            )}
            <div className={css.rowUnsetMarginLR}>
              <div className={css.viewAutoRenewalScheduleLink}>
                <a
                  onClick={() => {
                    this.onViewAutoRenewalSchedule(currentTransaction);
                  }}
                >
                  <FormattedMessage id={'TransactionPanel.viewAutoRenewalSchedule'} />
                </a>
              </div>
            </div>
          </div>
        )}
        <Modal
          id="TransactionPanel.autoRenewalScheduleModal"
          isOpen={this.state.autoRenewalModalOpen}
          onClose={() => {
            this.setState({ autoRenewalModalOpen: false });
          }}
          usePortal={true}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <PaymentScheduleBreakdown
            subscriptionPayments={this.state.autoRenewalSubscriptionPayments}
            subscriptionTotal={this.state.autoRenewalSubscriptionTotal}
            isProvider={isProvider}
            isAutoRenewal={true}
          />
        </Modal>
        <Modal
          id="TransactionPanel.stripeExpressModal"
          isOpen={this.state.showStripeExpressModal}
          onClose={() => {
            this.setState({ showStripeExpressModal: false });
          }}
          usePortal={true}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <StripeExpressStatusBox transactionId={currentTransaction.id} />
        </Modal>
      </>
    );

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={this.state.trxPanelAcceptInProgress}
        declineInProgress={this.state.trxPanelDeclineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        // onAcceptSale={() => onAcceptSale(currentTransaction, intl)}
        // onDeclineSale={() => onDeclineSale(currentTransaction.id)}
        onAcceptSale={() => {
          if (isCustomPaymentBooking) {
            if (IsCustomerAndWithinAutoRenewal) {
              this.onAcceptAutoRenewal(currentTransaction);
            } else {
              this.onAcceptSaleCustomPayment(currentTransaction.id);
            }
          } else {
            onAcceptSale(currentTransaction.id);
          }
        }}
        onDeclineSale={() => {
          if (isCustomPaymentBooking) {
            if (IsCustomerAndWithinAutoRenewal) {
              this.onDeclineAutoRenewal(currentTransaction.id);
            } else {
              this.onDeclineSaleCustomPayment(currentTransaction.id);
            }
          } else {
            onDeclineSale(currentTransaction.id);
          }
        }}
      />
    );

    const subscriptionButtons = (
      <SaleActionButtonsMaybe
        showButtons={true}
        acceptInProgress={acceptSubscriptionInProgress}
        declineInProgress={declineSubscriptionInProgress}
        acceptSaleError={acceptSubscriptionError}
        declineSaleError={declineSubscriptionError}
        onAcceptSale={() => onAcceptSubscription(currentTransaction)}
        onDeclineSale={() => onDeclineSubscription(currentTransaction.id)}
      />
    );

    const shouldShowCancelButton = () => {
      if (cancelAllowed && isProvider) {
        if (currentTransaction?.attributes?.lastTransition === 'transition/confirm-payment') {
          return false;
        }
      }
      return cancelAllowed;
    };
    const showCancelButton = shouldShowCancelButton();

    const booking = currentTransaction?.booking;
    const cancelButton = showCancelButton && (
      <CancelActionButtonMaybe
        onManageDisableScrolling={onManageDisableScrolling}
        transactionId={currentTransaction.id.uuid}
        isProvider={isProvider}
        booking={booking}
      />
    );

    const updatePaymentMethodForm = isCustomer && (
      <UpdatePaymentMethodMaybe
        onManageDisableScrolling={onManageDisableScrolling}
        transactionId={currentTransaction.id.uuid}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    function isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    }

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
              currentTransaction={currentTransaction}
            />

            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe
                transaction={currentTransaction}
                transactionRole={transactionRole}
                listing={currentListing}
              />
              {cancelButton &&
                <div className={css.mobileCancelBtn}>
                  {cancelButton}
                </div>
              }
            </div>

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
              isProvider={isProvider}
            />
            {showSendMessageForm ? (
              <SendMessageForm
                formId={this.sendMessageFormName}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
                transactionId={currentTransaction.id}
                onManageDisableScrolling={onManageDisableScrolling}
                isProvider={isProvider}
                listing={currentListing}
              />
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}

            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}
            {stateData.showSubscriptionButtons ? (
              <div className={css.mobileActionButtons}>{subscriptionButtons}</div>
            ) : null}
            <br />
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
              />

              <DetailCardHeadingsMaybe
                showDetailCardHeadings={true}
                listingTitle={listingTitle}
                subTitle={bookingSubTitle}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                />
              ) : null}
              <BreakdownMaybe
                className={css.breakdownContainer}
                transaction={currentTransaction}
                transactionRole={transactionRole}
                listing={currentListing}
              />
              {/* {isValidDate(new Date(subscriptionStartDate)) ? (
                <div className={css.bookingInfo}>
                  <p>• Subscription starts on {subscriptionStartDate}</p>
                </div>
              ) : null} */}
              {stateData.showSaleButtons ? (
                <>
                  {autoRenewalMessageBox}
                  <div className={css.desktopActionButtons}>{saleButtons}</div>
                </>
              ) : null}
              <br />
              {stateData.showSubscriptionButtons ? (
                <div className={css.desktopActionButtons}>{subscriptionButtons}</div>
              ) : null}

              <div className={css.desktopActionButtons}>{cancelButton}</div>
              <div className={css.desktopActionButtons}>{updatePaymentMethodForm}</div>
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
  cancelAllowed: false,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,

  cancelAllowed: bool.isRequired,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
