import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import stripePaymentFormCSS from '../../forms/StripePaymentForm/StripePaymentForm.module.css';
import css from './CheckoutCustomStripePaymentForm.module.scss';
import { PrimaryButton } from "../../components";
import { CustomStripePaymentForm } from "..";
import React, { useState } from "react";
import { bool, func, object, string } from "prop-types";
import CustomSavedCardDetails from '../../components/CustomSavedCardDetails/CustomSavedCardDetails';

const CheckoutCustomStripePaymentForm = props => {
    const {
        intl,
        authorDisplayName,
        customDefaultPaymentMethod,
        showCustomStripePaymentForm,
        clientSecret,
        handleCustomPaymentSubmit,
        trxSubmitInProgress
    } = props;

    const [initialMessage, setInitialMessage] = useState(null);

    const messagePlaceholder = intl.formatMessage(
        { id: 'StripePaymentForm.messagePlaceholder' },
        { name: authorDisplayName }
    );
    const messageOptionalText = intl.formatMessage({
        id: 'StripePaymentForm.messageOptionalText',
    });
    const initialMessageLabel = intl.formatMessage(
        { id: 'StripePaymentForm.messageLabel' },
        { messageOptionalText: messageOptionalText }
    );

    return (
        <React.Fragment>
            <h3 className={stripePaymentFormCSS.paymentHeading}>
                <FormattedMessage id="StripePaymentForm.payWithHeading" />
            </h3>
            {customDefaultPaymentMethod &&
                <CustomSavedCardDetails
                    className={stripePaymentFormCSS.paymentMethodSelector}
                    {...props}
                />
            }
            {!showCustomStripePaymentForm && customDefaultPaymentMethod &&
                <React.Fragment>
                    <h3 className={stripePaymentFormCSS.messageHeading}>
                        <FormattedMessage id="StripePaymentForm.messageHeading" />
                    </h3>
                    <label for="CheckoutPagePaymentForm-message">
                        {initialMessageLabel}
                    </label>
                    <textarea
                        id="CheckoutPagePaymentForm-message"
                        type="textarea"
                        name="initialMessage"
                        placeholder={messagePlaceholder}
                        value={initialMessage}
                        onChange={(e) => setInitialMessage(e.target.value)}
                        className={[stripePaymentFormCSS.message, css.initialMessageTextArea].join(" ")}
                    />
                    <div className={stripePaymentFormCSS.submitContainer}>
                        <p className={stripePaymentFormCSS.paymentInfo}>
                            {intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                        </p>
                        <PrimaryButton
                            className={stripePaymentFormCSS.submitButton}
                            type="button"
                            inProgress={trxSubmitInProgress}
                            onClick={() => handleCustomPaymentSubmit(null, customDefaultPaymentMethod.id, initialMessage)}>
                            <FormattedMessage id="StripePaymentForm.submitPaymentInfo" />
                        </PrimaryButton>
                    </div>
                </React.Fragment>
            }
            <br />
            {clientSecret && showCustomStripePaymentForm && (
                <CustomStripePaymentForm {...props} />
            )}
        </React.Fragment>
    )
}

CheckoutCustomStripePaymentForm.defaultProps = {
    intl: null,
    authorDisplayName: null,
    customDefaultPaymentMethod: null,
    showCustomStripePaymentForm: false,
    clientSecret: null,
    handleCustomPaymentSubmit: null
};

CheckoutCustomStripePaymentForm.propTypes = {
    intl: injectIntl,
    authorDisplayName: string.isRequired,
    customDefaultPaymentMethod: object,
    showCustomStripePaymentForm: bool.isRequired,
    clientSecret: string,
    handleCustomPaymentSubmit: func.isRequired
};

export default CheckoutCustomStripePaymentForm;