import React, { useState } from 'react';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { LINE_ITEM_TAX_ON_BOOKING_DEDUCTED_FROM_PROVIDER_PAYOUT, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemTaxOnBookingDeductedFromProviderPayout = props => {
  const { transaction, intl } = props;

  const taxLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_TAX_ON_BOOKING_DEDUCTED_FROM_PROVIDER_PAYOUT

  );

  const [taxTotal] = useState(taxLineItem ? taxLineItem : null);

  return taxTotal ? (
    <div className={css.lineItem}>
      <div className={css.itemLabel}>
        <FormattedMessage id="LineItemTaxOnBookingDeductedFromProviderPayout.tax.label" />
      </div>
      <div className={css.itemValue}>{formatMoney(intl, taxTotal.lineTotal)}</div>
    </div>
  ) : null;
};

LineItemTaxOnBookingDeductedFromProviderPayout.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTaxOnBookingDeductedFromProviderPayout;
