import React, { useState } from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_TAX, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemTaxMaybe = props => {
  const { transaction, intl } = props;

  const taxLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_TAX
  );

  const [taxTotal] = useState(taxLineItem ? taxLineItem : null);

  return taxTotal ? (
    <div className={css.lineItem}>
      <div className={css.itemLabel}>
        <FormattedMessage id="LineItemTaxMaybe.taxLabel" />
      </div>
      <div className={css.itemValue}>{formatMoney(intl, taxTotal.lineTotal)}</div>
    </div>
  ) : null;
};

LineItemTaxMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTaxMaybe;
