import React, { useState } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { PrimaryButton } from '../../components';
import { string, func, bool } from 'prop-types';
import stripePaymentFormCSS from '../StripePaymentForm/StripePaymentForm.module.css';
import css from './CustomStripePaymentForm.module.scss';

const ELEMENT_OPTIONS = {
    fields: {
        billingDetails: {
            // name: 'never',
            // email: 'never'
        }
    }
};

const CheckoutForm = (props) => {
    const {
        showInitialMessageInput,
        authorDisplayName,
        currentUserEmail,
        intl,
        handleCustomPaymentSubmit,
        clientSecret,
        ctaButtonTxt,
        trxSubmitInProgress
    } = props;

    const [status, setStatus] = useState();
    const [loading, setLoading] = useState(false);
    const [cardHolderName, setName] = useState('');
    const [initialMessage, setInitialMessage] = useState();
    const [stripeCustomerId, setStripeCustomerId] = useState();
    const [stripePaymentMethodId, setStripePaymentMethod] = useState();

    const stripe = useStripe();
    const elements = useElements();

    const getMessageInputPlaceholder = () => {
        if (showInitialMessageInput) {
            const messagePlaceholder = intl.formatMessage(
                { id: 'StripePaymentForm.messagePlaceholder' },
                { name: authorDisplayName }
            );

            const messageOptionalText = intl.formatMessage({
                id: 'StripePaymentForm.messageOptionalText',
            });

            const initialMessageLabel = intl.formatMessage(
                { id: 'StripePaymentForm.messageLabel' },
                { messageOptionalText: messageOptionalText }
            );

            return (
                <div>
                    <h3 className={stripePaymentFormCSS.messageHeading}>
                        <FormattedMessage id="StripePaymentForm.messageHeading" />
                    </h3>
                    <label for="CheckoutPagePaymentForm-message">
                        {initialMessageLabel}
                    </label>
                    <textarea
                        id="CheckoutPagePaymentForm-message"
                        type="textarea"
                        name="initialMessage"
                        placeholder={messagePlaceholder}
                        value={initialMessage}
                        onChange={(e) => setInitialMessage(e.target.value)}
                        className={[stripePaymentFormCSS.message, css.initialMessageTextArea].join(" ")}
                    />
                </div>
            )
        } else {
            return null;
        }
    }

    const handleSubmit = (event) => {
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true);

        if (stripeCustomerId && stripePaymentMethodId) {
            handleCustomPaymentSubmit(stripeCustomerId, stripePaymentMethodId);
        } else {
            stripe
                .confirmSetup({
                    elements,
                    redirect: 'if_required',
                    confirmParams: { return_url: window.location.href },
                }).then(({ setupIntent, error }) => {
                    if (error) {
                        setLoading(false);
                        console.log(`setupIntent error`, error)
                        console.error(error.message);
                        setStatus(error.message);
                    } else {
                        console.log(`setupIntent`, setupIntent)
                        setStripeCustomerId(setupIntent.customer);
                        setStripePaymentMethod(setupIntent.payment_method);
                        handleCustomPaymentSubmit(setupIntent.customer, setupIntent.payment_method, initialMessage);
                    }
                });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <label htmlFor="cardHolderName">Full Name</label>
            <input
                id="cardHolderName"
                className={css.cardHolderName}
                required
                placeholder="First and last name"
                value={cardHolderName}
                onChange={(e) => setName(e.target.value)}
            />
            <PaymentElement options={ELEMENT_OPTIONS} />
            {getMessageInputPlaceholder()}
            <div className={stripePaymentFormCSS.submitContainer}>
                {showInitialMessageInput &&
                    <p className={stripePaymentFormCSS.paymentInfo}>
                        {intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                    </p>
                }
                <PrimaryButton
                    className={stripePaymentFormCSS.submitButton}
                    type="submit"
                    inProgress={loading || trxSubmitInProgress}
                    disabled={!stripe || loading || trxSubmitInProgress}>
                    <FormattedMessage id={loading || trxSubmitInProgress ? 'Processing...' : ctaButtonTxt} />
                </PrimaryButton>
                {status && <p>{status}</p>}
            </div>
        </form>
    );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CustomStripePaymentFormComponent = (props) => {
    const { clientSecret } = props;
    const [theme] = useState('stripe');

    return (
        <>
            {stripePromise && clientSecret && (
                <Elements
                    stripe={stripePromise}
                    options={{ clientSecret, appearance: { theme } }}>
                    <CheckoutForm {...props} clientSecret={clientSecret} />
                </Elements>
            )}
        </>
    );
};

CustomStripePaymentFormComponent.defaultProps = {
    handleCustomPaymentSubmit: null,
    clientSecret: null,
    ctaButtonTxt: 'StripePaymentForm.submitPaymentInfo',
    currentUserEmail: null,
    showInitialMessageInput: false,
    authorDisplayName: null,
    intl: null,
    trxSubmitInProgress: false,
};

CustomStripePaymentFormComponent.propTypes = {
    handleCustomPaymentSubmit: func,
    clientSecret: string.isRequired,
    ctaButtonTxt: string,
    currentUserEmail: string.isRequired,
    showInitialMessageInput: bool.isRequired,
    authorDisplayName: string,
    intl: injectIntl,
    trxSubmitInProgress: bool.isRequired,
}

const CustomStripePaymentForm = compose(injectIntl)(CustomStripePaymentFormComponent);

export default CustomStripePaymentForm;