import React, { useState } from 'react';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { LINE_ITEM_SUBTOTAL_PLUS_TAX, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemSubtotalPlusTax = props => {
  const { transaction, intl } = props;

  const subtotalPlusTaxLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_SUBTOTAL_PLUS_TAX
  );

  const [subtotalPlusTax] = useState(subtotalPlusTaxLineItem ? subtotalPlusTaxLineItem : null);

  return subtotalPlusTax ? (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>
          <FormattedMessage id="LineItemSubtotalPlusTax.label" />
        </div>
        <div className={css.totalPrice}>{formatMoney(intl, subtotalPlusTax.lineTotal)}</div>
      </div>
    </>
  ) : null;
};

LineItemSubtotalPlusTax.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSubtotalPlusTax;
