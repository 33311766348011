import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { DIMENSIONS_FT, DIMENSIONS_INCH, DIMENSIONS_MM, DIMENSIONS_PX, propTypes } from '../../util/types';
import { Form, Button, FieldTextInput, FieldCurrencyInput, FieldSelect, FieldCheckboxGroup } from '../../components';
import config from '../../config';
import * as validators from '../../util/validators';
import { required, composeValidators } from '../../util/validators';
import css from './EditListingPoliciesForm.module.scss';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { findOptionsForSelectFilter } from '../../util/search';
import { filters as filterConfig } from '../../marketplace-custom-config';

const { Money } = sdkTypes;
export const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;

      const rulesLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesLabel',
      });
      const rulesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesPlaceholder',
      });
      const rulesRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.rulesRequiredMessage',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const installationFeeMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.installationFeeMessage',
      });

      const installationFeePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.installationFeePlaceholderMessage',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPoliciesForm.installationFeeRequired',
        })
      );

      const tenKInSubunits = 10000 * 100;
      const maxPrice = new Money(tenKInSubunits, config.currency);

      const maxPriceRequired = validators.moneySubUnitAmountAtMost(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooHigh',
          },
          {
            maxPrice: formatMoney(intl, maxPrice),
          }
        ),
        tenKInSubunits
      );

      const priceValidators = validators.composeValidators(priceRequired, maxPriceRequired);

      const installationWaivedLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.installationWaivedLabelMessage',
      });

      const installationWaivedPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.installationWaivedPlaceholderMessage',
      });

      const installationRulesTipMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.installationRulesTipMessage',
      });

      const installationWaivedOptions = [
        {
          key: '""',
          label: 'Never waive installation fee',
        },
        {
          key: '30',
          label: '1 month (30 days)',
        },
        {
          key: '180',
          label: '6 months (180 days)',
        },
        {
          key: '365',
          label: '1 year (365 days)',
        },
      ];

      const dimensionsOfArtworkLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.dimensionsOfArtworkLabelMessage',
      });
      const dimensionsOfArtworkPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.dimensionsOfArtworkPlaceholderMessage',
      });
      const dimensionsOfArtworkSizeUnits = [
        {
          key: DIMENSIONS_FT,
          label: DIMENSIONS_FT,
        },
        {
          key: DIMENSIONS_INCH,
          label: DIMENSIONS_INCH,
        },
        {
          key: DIMENSIONS_MM,
          label: DIMENSIONS_MM,
        },
        {
          key: DIMENSIONS_PX,
          label: DIMENSIONS_PX,
        },
      ];

      const optionsSupportedFiles = findOptionsForSelectFilter('supported_files_types', filterConfig);

      const disableInstallationFeeWaived = !values?.installationFee || values?.installationFee?.amount === 0;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldTextInput
            id="rules"
            name="rules"
            className={css.policy}
            type="textarea"
            label={rulesLabelMessage}
            placeholder={rulesPlaceholderMessage}
            validate={composeValidators(required(rulesRequiredMessage))}
          />
          <p className={css.tip}>{installationRulesTipMessage}</p>

          <FieldCurrencyInput
            id="installationFee"
            name="installationFee"
            className={css.priceInput}
            label={installationFeeMessage}
            placeholder={installationFeePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />

          <FieldSelect
            className={css.installationWaived}
            name={'installationWaived'}
            id={'installationWaived'}
            label={installationWaivedLabelMessage}
            placeholder={installationWaivedPlaceholderMessage}
            disabled={disableInstallationFeeWaived}
          >
            <option disabled hidden value="">
              {installationWaivedPlaceholderMessage}
            </option>
            {installationWaivedOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>

          <br />

          <label>
            <FormattedMessage id="EditListingPoliciesForm.dimensionsOfArtworkTitle" />
          </label>
          <div className={css.dimensionContainer}>
            <div className={css.rowUnsetMarginLR}>
              <div className={css.col3}>
              <FieldTextInput
                  className={css.input}
                  id="dimensionsOfArtworkWidth"
                  name="dimensionsOfArtworkWidth"
                  type="number"
                  label="Width"
                  schemaType={'long'}
                  min={0}
                />
              </div>
              <div className={css.col3}>
              <FieldTextInput
                  className={css.input}
                  id="dimensionsOfArtworkHeight"
                  name="dimensionsOfArtworkHeight"
                  type="number"
                  label="Height"
                  schemaType={'long'}
                  min={0}
                />

              </div>
              <div className={css.col4}>
                <FieldSelect
                  className={css.input}
                  name={'dimensionsOfArtworkUnit'}
                  id={'dimensionsOfArtworkUnit'}
                  label={dimensionsOfArtworkLabelMessage}
                >
                  <option disabled hidden value="">
                    {dimensionsOfArtworkPlaceholderMessage}
                  </option>
                  {dimensionsOfArtworkSizeUnits.map(c => (
                    <option key={c.key} value={c.key}>
                      {c.label}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            </div>
          </div>

          <FieldCheckboxGroup
            className={css.supportedFiles}
            label={intl.formatMessage({ id: 'EditListingPoliciesForm.supportedFileTypesLabel' })}
            id={'supported_files_types'}
            name={'supported_files_types'}
            options={optionsSupportedFiles}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
