import { string } from 'prop-types';
import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import {
  LINE_ITEM_SUBTOTAL,
  propTypes
} from '../../util/types';

import { formatMoney } from '../../util/currency';
import css from './BookingBreakdown.module.css';

const LineItemSubTotalMaybe = props => {
  const { transaction, intl } = props;
  const { lineItems } = transaction.attributes;

  const subtotal = lineItems.find(
    item => item.code === LINE_ITEM_SUBTOTAL
  );

  if (!subtotal) {
    return null;
  }

  const { lineTotal } = subtotal;

  return lineTotal ? (
    <>
      <hr className={css.totalDivider} />
      <div className={css.subTotalLineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.subTotal" />
        </span>
        <span className={css.itemValue}>
          {formatMoney(intl, lineTotal)}
        </span>
      </div>
      <hr className={css.totalDivider} />
    </>
  ) : null;
};

LineItemSubTotalMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  userRole: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSubTotalMaybe;
