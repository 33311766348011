import { getSubtotalLineItem } from "../components/BookingBreakdown/subscription/utils";

export const RESET_PAYMENT_LINE_ITEMS = 'app/paymentLineItems/RESET_PAYMENT_LINE_ITEMS';
export const UPDATE_BASE_PRICE = 'app/paymentLineItems/UPDATE_BASE_PRICE';
export const UPDATE_RESIDENTIAL_MONTH = 'app/paymentLineItems/UPDATE_RESIDENTIAL_MONTH';
export const UPDATE_SUBTOTAL = 'app/paymentLineItems/UPDATE_SUBTOTAL';
export const UPDATE_SUBSCRIPTION_PAYMENTS = 'app/paymentLineItems/UPDATE_SUBTOTAL';
export const UPDATE_SUBSCRIPTION_TOTAL = 'app/paymentLineItems/UPDATE_SUBTOTAL';

const initialState = {
  baseOrSubscriptionFirstMonthPrice: null,
  residualMonth: null,
  subtotal: null,
  subscriptionPayments: null,
  subscriptionTotal: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case RESET_PAYMENT_LINE_ITEMS:
      return { ...state, paymentLineItems: payload };
    case UPDATE_BASE_PRICE:
      return { ...state, baseOrSubscriptionFirstMonthPrice: payload };
    case UPDATE_RESIDENTIAL_MONTH:
      return { ...state, residualMonth: payload };
    case UPDATE_SUBTOTAL:
      return { ...state, subtotal: payload };
    case UPDATE_SUBSCRIPTION_PAYMENTS:
      return { ...state, subscriptionPayments: payload };
    case UPDATE_SUBSCRIPTION_TOTAL:
      return { ...state, subscriptionTotal: payload };
    default:
      return state;
  }
}

export const resetPaymentLineItems = paymentLineItems => ({
  type: RESET_PAYMENT_LINE_ITEMS,
  payload: paymentLineItems,
});
export const updateBasePriceSuccess = baseOrSubscriptionFirstMonthPrice => ({
  type: UPDATE_BASE_PRICE,
  payload: baseOrSubscriptionFirstMonthPrice,
});
export const updateResidualMonthSuccess = residualMonth => ({
  type: UPDATE_RESIDENTIAL_MONTH,
  payload: residualMonth,
});
export const updateSubtotalSuccess = subtotal => ({
  type: UPDATE_SUBTOTAL,
  payload: subtotal,
});
export const updateSubscriptionPaymentsSuccess = subscriptionPayments => ({
  type: UPDATE_SUBSCRIPTION_PAYMENTS,
  payload: subscriptionPayments,
});
export const updateSubscriptionTotalSuccess = subscriptionTotal => ({
  type: UPDATE_SUBSCRIPTION_TOTAL,
  payload: subscriptionTotal,
});

export const reset = params => (dispatch, getState, sdk) => {
  dispatch(resetPaymentLineItems({
    baseOrSubscriptionFirstMonthPrice: null,
    residualMonth: null,
    subtotal: null,
    subscriptionPayments: null,
    subscriptionTotal: null,
  }));
};

export const updateBasePrice = params => (dispatch, getState, sdk) => {
  dispatch(updateBasePriceSuccess(params));
};

export const updateResidualMonth = params => (dispatch, getState, sdk) => {
  dispatch(updateResidualMonthSuccess(params));
};

export const updateSubtotal = subtotalMoneyAmount => (dispatch, getState, sdk) => {
  const subtotal = getSubtotalLineItem(subtotalMoneyAmount);
  dispatch(updateSubtotalSuccess(subtotal));
};

export const updateSubscriptionPayments = params => (dispatch, getState, sdk) => {
  dispatch(updateSubscriptionPaymentsSuccess(params));
};

export const updateSubscriptionTotal = params => (dispatch, getState, sdk) => {
  dispatch(updateSubscriptionTotalSuccess(params));
};