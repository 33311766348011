import React, { Component } from 'react';
import { string, shape, number, object } from 'prop-types';
// This MultiTouch lib is used for 2-finger panning.
// which prevents user to experience map-scroll trap, while scrolling the page.
// https://github.com/mapbox/mapbox-gl-js/issues/2618
// TODO: we should add an overlay with text "use two fingers to pan".
import MultiTouch from 'mapbox-gl-multitouch';
import uniqueId from 'lodash/uniqueId';
import { circlePolyline } from '../../util/maps';
import config from '../../config';

const mapMarker = mapsConfig => {
  const { enabled, url, width, height, draggable } = mapsConfig.customMarker;
  if (enabled) {
    const element = document.createElement('div');
    element.style.backgroundImage = `url(${url})`;
    element.style.width = `${width}px`;
    element.style.height = `${height}px`;

    return new window.mapboxgl.Marker({ element });
  } else {
    return new window.mapboxgl.Marker({ draggable: draggable });
  }
};

const circleLayer = (center, mapsConfig, layerId) => {
  const path = circlePolyline(center, mapsConfig.fuzzy.offset).map(([lat, lng]) => [lng, lat]);
  return {
    id: layerId,
    type: 'fill',
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [path],
        },
      },
    },
    paint: {
      'fill-color': mapsConfig.fuzzy.circleColor,
      'fill-opacity': 0.2,
    },
  };
};

const generateFuzzyLayerId = () => {
  return uniqueId('fuzzy_layer_');
};

class DynamicMapboxMap extends Component {
  constructor(props) {
    super(props);

    this.mapContainer = null;
    this.map = null;
    this.centerMarker = null;
    this.fuzzyLayerId = generateFuzzyLayerId();

    this.updateFuzzyCirclelayer = this.updateFuzzyCirclelayer.bind(this);
  }
  componentDidMount() {
    const { center, zoom, mapsConfig } = this.props;
    const position = [center.lng, center.lat];

    this.map = new window.mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v10',
      center: position,
      zoom,
      scrollZoom: true,
    });

    const geocoder = new MapboxGeocoder({
      // Initialize the geocoder
      accessToken: window.mapboxgl.accessToken, // Set the access token
      mapboxgl: window.mapboxgl, // Set the mapbox-gl instance
      marker: false, // Do not use the default marker style
    });

    this.map.addControl(geocoder);
    this.map.addControl(new window.mapboxgl.NavigationControl({ showCompass: false }), 'top-left');
    this.map.addControl(new MultiTouch());

    // Listen for the `result` event from the Geocoder
    // `result` event is triggered when a user makes a selection
    //  Add a marker at the result's coordinates

    if (mapsConfig.fuzzy.enabled) {
      this.map.on('load', () => {
        this.map.addLayer(circleLayer(center, mapsConfig, this.fuzzyLayerId));
      });
    } else {
      this.centerMarker = mapMarker(mapsConfig);
      this.centerMarker.setLngLat(position).addTo(this.map);
      this.centerMarker.on('dragend', this.onDragEnd); //.on('dragend', onDragEnd);
    }

    geocoder.on('result', event => {
      // map.getSource('single-point').setData(event.result.geometry);
      const coordinates = event.result.geometry.coordinates;
      this.centerMarker.setLngLat(coordinates);
      window.$lat = coordinates[1];
      window.$lng = coordinates[0];

      window.setFormValue('latitude', coordinates[1]);
      window.setFormValue('longitude', coordinates[0]);

      fetch(
        'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
          coordinates[0] +
          ',' +
          coordinates[1] +
          '.json?types=poi,address&access_token=' +
          config.maps.mapboxAccessToken
      )
        .then(response => response.json())
        .then(jsonData => {
          // jsonData is parsed json object received from url
          console.log(jsonData.features[0].place_name);

          window.setFormValue('address', jsonData.features[0].place_name);
          //window.setFormValue('location', jsonData.features[0].place_name)
        })
        .catch(error => {
          // handle your errors here
          console.error(error);
        });
    });
  }
  componentWillUnmount() {
    if (this.map) {
      this.centerMarker = null;
      this.map.remove();
      this.map = null;
    }
  }
  componentDidUpdate(prevProps) {
    if (!this.map) {
      return;
    }

    const { center, zoom, mapsConfig } = this.props;
    const { lat, lng } = center;
    const position = [lng, lat];

    // zoom change
    if (zoom !== prevProps.zoom) {
      this.map.setZoom(this.props.zoom);
    }

    const centerChanged = lat !== prevProps.center.lat || lng !== prevProps.center.lng;

    // center marker change
    if (this.centerMarker && centerChanged) {
      this.centerMarker.setLngLat(position);
      this.map.setCenter(position);
    }

    // fuzzy circle change
    if (mapsConfig.fuzzy.enabled && centerChanged) {
      if (this.map.loaded()) {
        this.updateFuzzyCirclelayer();
      } else {
        this.map.on('load', this.updateFuzzyCirclelayer);
      }
    }

    // NOTE: mapsConfig changes are not handled
  }

  onDragEnd() {
    window.$lat = this._lngLat.lat;
    window.$lng = this._lngLat.lng;

    window.setFormValue('latitude', this._lngLat.lat);
    window.setFormValue('longitude', this._lngLat.lng);

    fetch(
      'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
        this._lngLat.lng +
        ',' +
        this._lngLat.lat +
        '.json?types=poi,address&access_token=' +
        config.maps.mapboxAccessToken
    )
      .then(response => response.json())
      .then(jsonData => {
        // jsonData is parsed json object received from url
        console.log(jsonData.features[0].place_name);

        window.setFormValue('address', jsonData.features[0].place_name);
        //window.setFormValue('location', jsonData.features[0].place_name)
      })
      .catch(error => {
        // handle your errors here
        console.error(error);
      });
  }

  updateFuzzyCirclelayer() {
    if (!this.map) {
      // map already removed
      return;
    }
    const { center, mapsConfig } = this.props;
    const { lat, lng } = center;
    const position = [lng, lat];

    this.map.removeLayer(this.fuzzyLayerId);

    // We have to use a different layer id to avoid Mapbox errors
    this.fuzzyLayerId = generateFuzzyLayerId();
    this.map.addLayer(circleLayer(center, mapsConfig, this.fuzzyLayerId));

    this.map.setCenter(position);
  }
  render() {
    const { containerClassName, mapClassName } = this.props;
    return (
      <div className={containerClassName}>
        <div className={mapClassName} ref={el => (this.mapContainer = el)} />
      </div>
    );
  }
}

DynamicMapboxMap.defaultProps = {
  address: '',
  center: null,
  zoom: config.maps.fuzzy.enabled ? config.maps.fuzzy.defaultZoomLevel : 11,
  mapsConfig: config.maps,
};

DynamicMapboxMap.propTypes = {
  address: string, // not used
  center: shape({
    lat: number.isRequired,
    lng: number.isRequired,
  }).isRequired,
  zoom: number,
  mapsConfig: object,
};

export default DynamicMapboxMap;
