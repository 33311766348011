import { retrieveStripeAccount } from '../../util/api';

export const FETCH_STRIPE_EXPRESS_REQUEST = 'app/StripeExpressPayoutPage/FETCH_STRIPE_EXPRESS_REQUEST';
export const FETCH_STRIPE_EXPRESS_SUCCESS = 'app/StripeExpressPayoutPage/FETCH_STRIPE_EXPRESS_SUCCESS';
export const FETCH_STRIPE_EXPRESS_ERROR = 'app/StripeExpressPayoutPage/FETCH_STRIPE_EXPRESS_ERROR';

const initialState = {
  stripeExpress: null,
  fetchStripeExpressInProgress: false,
  fetchStripeExpressError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_STRIPE_EXPRESS_REQUEST:
      return { ...state, fetchStripeExpressInProgress: true, fetchStripeExpressError: null };
    case FETCH_STRIPE_EXPRESS_SUCCESS:
      return { ...state, stripeExpress: payload, fetchStripeExpressInProgress: false, fetchStripeExpressError: null };
    case FETCH_STRIPE_EXPRESS_ERROR:
      return { ...state, fetchStripeExpressInProgress: false, fetchStripeExpressError: payload };
    default:
      return state;
  }
}

export const fetchStripeExpressRequest = () => ({
  type: FETCH_STRIPE_EXPRESS_REQUEST,
});

export const fetchStripeExpressSuccess = stripeExpress => ({
  type: FETCH_STRIPE_EXPRESS_SUCCESS,
  payload: stripeExpress,
});

export const fetchStripeExpressError = error => ({
  type: FETCH_STRIPE_EXPRESS_ERROR,
  payload: error,
});

export const fetchStripeExpress = (params = null) => (dispatch, getState, sdk) => {
  dispatch(fetchStripeExpressRequest())
  return retrieveStripeAccount()
    .then(stripeExpress => {
      if (stripeExpress) {
        dispatch(fetchStripeExpressSuccess(stripeExpress));
      }
      return stripeExpress;
    })
    .catch(error => {
      dispatch(fetchStripeExpressError(error));
      // log.error(error, 'fetch-stripe-account-failed', { stripeMessage: error });
      throw error;
    });
};

export const loadData = () => {
  return fetchStripeExpress();
};
