import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { TRANSITIONS, TRANSITION_ENQUIRE } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getInboxTransactionDetails } from '../../util/api';

const { types } = require('sharetribe-flex-sdk');
const { UUID } = types;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  const dispatchAddMarketEntitiesAndFetchOrdersOrSalesSuccess = response => {
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchOrdersOrSalesSuccess(response));
  };

  const handleCustomTrxMerge = response => {
    const trxToMap = []
    for (let i = 0; i < response.data.data.length; i++) {
      if (response.data.data[i].attributes.lastTransition == TRANSITION_ENQUIRE) {
        trxToMap.push({
          transactionId: response.data.data[i].id.uuid,
          indexPos: i
        });
      }
    }
    if (trxToMap.length > 0) {
      getInboxTransactionDetails({ trxToMap: trxToMap })
        .then(result => {
          if (result && result.length > 0) {
            mapTrxToCustomPaymentTrx(result, response);
          } else {
            dispatchAddMarketEntitiesAndFetchOrdersOrSalesSuccess(response);
          }
        })
        .catch(err => {
          console.error(err);
          dispatchAddMarketEntitiesAndFetchOrdersOrSalesSuccess(response);
        });
    } else {
      dispatchAddMarketEntitiesAndFetchOrdersOrSalesSuccess(response);
    }
  };

  const mapTrxToCustomPaymentTrx = (customPaymentTrx, response) => {
    for (let i = 0; i < customPaymentTrx.length; i++) {
      const trxAttr = customPaymentTrx[i].transaction.attributes;
      const indexPos = customPaymentTrx[i].indexPos;
      response.data.data[indexPos].attributes.lastTransition = trxAttr.lastTransition;
      response.data.data[indexPos].attributes.isBankPaymentBooking = true;
      response.data.data[indexPos].booking = customPaymentTrx[i].booking;
      response.data.data[indexPos].relationships.booking.data = {
        id: new UUID(response.data.data[indexPos].booking.id.uuid),
        type: 'booking'
      };
      response.data.included.push({
        id: new UUID(response.data.data[indexPos].booking.id.uuid),
        type: 'booking',
        attributes: response.data.data[indexPos].booking.attributes
      });
    }
    dispatchAddMarketEntitiesAndFetchOrdersOrSalesSuccess(response);
  };

  const apiQueryParams = {
    only: onlyFilter,
    lastTransitions: TRANSITIONS,
    include: ['provider', 'provider.profileImage', 'customer', 'customer.profileImage', 'booking'],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      handleCustomTrxMerge(response);
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
