import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-toronto',
    predictionPlace: {
      address: 'Toronto, Ontario',
      bounds: new LatLngBounds(new LatLng(43.867, -79.1113), new LatLng(43.547, -79.639)),
    },
  },
  {
    id: 'default-chatham-kent',
    predictionPlace: {
      address: 'Chatham-Kent, Ontario',
      bounds: new LatLngBounds(new LatLng(42.651, -81.624), new LatLng(42.059, -82.485)),
    },
  },
  {
    id: 'default-london',
    predictionPlace: {
      address: 'London, Ontario',
      bounds: new LatLngBounds(new LatLng(43.073, -81.106), new LatLng(42.825, -81.3911)),
    },
  },
  {
    id: 'default-ottawa',
    predictionPlace: {
      address: 'Ottawa, Ontario',
      bounds: new LatLngBounds(new LatLng(45.536, -75.246), new LatLng(44.962, -76.354)),
    },
  },
  {
    id: 'default-windsor',
    predictionPlace: {
      address: 'Windsor, Ontario',
      bounds: new LatLngBounds(new LatLng(42.355, -82.891), new LatLng(42.233, -83.115)),
    },
  },
];
export default defaultLocations;
