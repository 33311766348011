import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './BookingBreakdown.module.css';

const LineItemRefundNotes = props => {
  const { booking } = props;
  const refundNotes = booking?.attributes?.refundNotes;

  if (!refundNotes) {
    return null;
  }

  return (
    <div className={css.refundNotes}>
      <div className={css.row}>
        <div className={css.col12}>
          <h6>Refund notes</h6>
        </div>
        <div className={css.col12}>
          <p className={css.refundNotes}>
            {refundNotes}
          </p>
        </div>
      </div>
    </div>
  );
};

LineItemRefundNotes.propTypes = {
  booking: propTypes.booking.isRequired
};

export default LineItemRefundNotes;
