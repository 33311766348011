import { getTaxInfo, insertTaxInfo, updateTaxInfo } from '../../util/api';

export const FETCH_TAX_INFO = 'app/TaxSettingsPage/FETCH_TAX_INFO';
export const UPDATE_TAX_INFO = 'app/TaxSettingsPage/UPDATE_TAX_INFO';
export const ADD_TAX_INFO = 'app/TaxSettingsPage/ADD_TAX_INFO';
export const SAVE_TAX_INFO = 'app/TaxSettingsPage/SAVE_TAX_INFO';
export const SAVE_TAX_INFO_IN_PROGRESS = 'app/TaxSettingsPage/SAVE_TAX_INFO_IN_PROGRESS';
export const SAVE_TAX_INFO_ERROR = 'app/TaxSettingsPage/SAVE_TAX_INFO_ERROR';

const initialState = {
  taxInfo: null,
  saveTaxInfoError: null,
  saveTaxInfoInProgress: false
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TAX_INFO:
    case UPDATE_TAX_INFO:
      return { ...state, taxInfo: payload, saveTaxInfoInProgress: false, saveTaxInfoError: null };
    case ADD_TAX_INFO:
      return { ...state, taxInfo: payload, saveTaxInfoInProgress: false };
    case SAVE_TAX_INFO:
      return { ...state, taxInfo: payload, saveTaxInfoInProgress: false };
    case SAVE_TAX_INFO_IN_PROGRESS:
      return { ...state, saveTaxInfoInProgress: payload };
    default:
      return state;
  }
}

export const fetchTaxInfoRequest = taxInfo => ({
  type: FETCH_TAX_INFO,
  payload: taxInfo,
});
export const updateTaxInfoRequest = taxInfo => ({
  type: UPDATE_TAX_INFO,
  payload: taxInfo,
});
export const addTaxInfoSuccess = taxInfo => ({
  type: ADD_TAX_INFO,
  payload: taxInfo,
});
export const saveTaxInfoSuccess = taxInfo => ({
  type: SAVE_TAX_INFO,
  payload: taxInfo,
});
export const saveTaxInfoInProgress = payload => ({
  type: SAVE_TAX_INFO_IN_PROGRESS,
  payload,
});
export const saveTaxInfoError = error => ({
  type: SAVE_TAX_INFO_ERROR,
  payload: error,
  error: true,
});

export const addTaxInfo = params => (dispatch, getState, sdk) => {
  dispatch(saveTaxInfoInProgress(true));
  return insertTaxInfo(params)
    .then(taxInfo => {
      dispatch(addTaxInfoSuccess(taxInfo));
      dispatch(updateTaxInfoRequest(taxInfo));
      return taxInfo;
    })
    .catch(error => {
      dispatch(saveTaxInfoInProgress(false));
      console.error(error, 'insert-tax-info-failed');
    });
};

export const saveTaxInfo = params => (dispatch, getState, sdk) => {
  dispatch(saveTaxInfoInProgress(true));
  return updateTaxInfo(params)
    .then(taxInfo => {
      dispatch(saveTaxInfoSuccess(taxInfo));
      dispatch(updateTaxInfoRequest(taxInfo));
      return taxInfo;
    })
    .catch(error => {
      dispatch(saveTaxInfoInProgress(false));
      console.error(error, 'insert-tax-info-failed');
    });
};

export const fetchTaxInfo = (params = null) => (dispatch, getState, sdk) => {
  return getTaxInfo()
    .then(taxInfo => {
      if (taxInfo) {
        dispatch(fetchTaxInfoRequest(taxInfo));
      }
      return taxInfo;
    })
    .catch(error => {
      console.error(error, 'fetch-tax-info-failed');
    });
};

export const loadData = () => {
  return fetchTaxInfo();
};
