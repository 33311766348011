import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput, FieldRadioButton } from '../../components';
import { numberAtLeast, numberBetween } from '../../util/validators';
import ManageAvailabilityCalendar from './ManageAvailabilityCalendar';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import css from './EditListingAvailabilityForm.module.css';

export class EditListingAvailabilityFormComponent extends Component {
  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            className,
            rootClassName,
            disabled,
            ready,
            handleSubmit,
            //intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateError,
            updateInProgress,
            availability,
            availabilityPlan,
            listingId,
            intl,
            listing,
          } = formRenderProps;

          const errorMessage = updateError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingAvailabilityForm.updateFailed" />
            </p>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          const minBookingPeriodMessage = intl.formatMessage({
            id: 'EditListingAvailabilityForm.minBookingPeriod',
          });
          const minBookingPeriodPlaceholderMessage = intl.formatMessage({
            id: 'EditListingAvailabilityForm.minBookingPeriodPlaceholder',
          });
          const minBookingPeriodTipMessage = intl.formatMessage({
            id: 'EditListingPoliciesForm.minBookingPeriodTipMessage',
          });

          const availableSeatsMessage = intl.formatMessage({
            id: 'EditListingAvailabilityForm.availableSeats',
          });
          const availableSeatsPlaceholderMessage = intl.formatMessage({
            id: 'EditListingAvailabilityForm.availableSeatsdPlaceholder',
          });
          const availableSeatsTipMessage = intl.formatMessage({
            id: 'EditListingPoliciesForm.availableSeatsTipMessage',
          });

          const bufferPeriodMessage = intl.formatMessage({
            id: 'EditListingAvailabilityForm.bufferPeriod',
          });
          const bufferPeriodPlaceholderMessage = intl.formatMessage({
            id: 'EditListingAvailabilityForm.bufferPeriodPlaceholder',
          });
          const bufferPeriodTipMessage = intl.formatMessage({
            id: 'EditListingPoliciesForm.bufferPeriodTipMessage',
          });

          const fieldRequiredMessage = intl.formatMessage({
            id: 'EditListingAvailabilityForm.fieldRequiredMessage',
          });

          const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
          const currentCategory = listing?.attributes?.publicData?.category;
          const maxNumberOfSeats =
            categoryOptions.find(c => c.key === currentCategory)?.maxSeats ?? 99;

          const availableSeatsRequiredMessage = intl.formatMessage(
            {
              id: 'EditListingAvailabilityForm.availableSeatsRequiredMessage',
            },
            {
              maxNumberOfSeats,
            }
          );

          const maxBufferPeriodInDays = 60;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessage}
              <div className={css.calendarWrapper}>
                <ManageAvailabilityCalendar
                  availability={availability}
                  availabilityPlan={availabilityPlan}
                  listingId={listingId}
                />
              </div>

              <FieldTextInput
                id="minBookingPeriod"
                name="minBookingPeriod"
                className={css.textField}
                type="number"
                label={minBookingPeriodMessage}
                min={1}
                placeholder={minBookingPeriodPlaceholderMessage}
                validate={numberAtLeast(fieldRequiredMessage, 1)}
              />
              <p className={css.tip}>{minBookingPeriodTipMessage}</p>

              <FieldTextInput
                id="availableSeats"
                name="availableSeats"
                className={css.textField}
                type="number"
                label={availableSeatsMessage}
                min={1}
                max={maxNumberOfSeats}
                placeholder={availableSeatsPlaceholderMessage}
                validate={numberBetween(availableSeatsRequiredMessage, 1, maxNumberOfSeats)}
              />
              <p className={css.tip}>{availableSeatsTipMessage}</p>

              <FieldTextInput
                id="bufferPeriod"
                name="bufferPeriod"
                className={css.textField}
                type="number"
                label={bufferPeriodMessage}
                min={2}
                max={maxBufferPeriodInDays}
                placeholder={bufferPeriodPlaceholderMessage}
              />
              <p className={css.tip}>{bufferPeriodTipMessage}</p>

              <h4 className={css.autoRenewalTitle}>
                <FormattedMessage id="EditListingAvailabilityForm.autoRenewalTitle" />
              </h4>
  
              <FieldRadioButton
                id="listingAutoRenewalYes"
                name="listingAutoRenewalAllowed"
                label={intl.formatMessage({ id: 'EditListingAvailabilityForm.yes' })}
                value={'yes'}
              />
              <FieldRadioButton
                id="listingAutoRenewalNo"
                name="listingAutoRenewalAllowed"
                label={intl.formatMessage({ id: 'EditListingAvailabilityForm.no' })}
                value={'no'}
              />
                          <p className={css.tip}>
                <FormattedMessage id="EditListingAvailabilityForm.autoRenewalTip" />
              </p>

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingAvailabilityFormComponent.defaultProps = {
  updateError: null,
};

EditListingAvailabilityFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  availability: object.isRequired,
  availabilityPlan: propTypes.availabilityPlan.isRequired,
};

export default compose(injectIntl)(EditListingAvailabilityFormComponent);
