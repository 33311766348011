import Button from '../Button/Button';
import css from './RefundBreakdownMaybe.module.scss';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { beginCancellation, getRefundAmounts } from '../../util/api';
import { func, string } from 'prop-types';
import { formatMoney } from '../../util/currency';
import { FormattedDate, FormattedMessage } from 'react-intl';
import dateFormatOptions from '../../util/dateFormatOptions';
import config from '../../config';

const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

const RefundBreakdownMaybe = forwardRef((props, ref) => {
    const { setCancelModalOpen, transactionId, intl, isProvider } = props;

    const [reasonForCancelling, setReasonForCancelling] = useState(null);
    const [confirmCancellation, setConfirmCancellation] = useState(false);
    const [cancelInProgress, setCancelInProgress] = useState(false);
    const [cancelErrorMessage, setCancelErrorMessage] = useState(undefined);

    const [refundAmounts, setRefundAmounts] = useState(null);

    const [installationFee, setInstallationFee] = useState();
    const [taxRefundAmount, setTaxRefundAmount] = useState();
    const [amount, setAmount] = useState();
    const [remainingTermBreakdown, setRemainingTermBreakdown] = useState();
    const [reversalAmount, setReversalAmount] = useState();

    const [cancellationIsValid, setCancellationIsValid] = useState(true);
    const [bookingStillPending, setBookingStillPending] = useState(false);

    useImperativeHandle(ref, () => ({
        fetchRefundAmounts() {
            setCancelInProgress(null);
            setRefundAmounts(null);
            setCancelErrorMessage(undefined);
        }
    }));

    useEffect(() => {
        if (refundAmounts) {
            setInstallationFee(refundAmounts?.installationFee);
            setTaxRefundAmount(refundAmounts?.taxRefundAmount);
            setAmount(refundAmounts?.amount);
            setRemainingTermBreakdown(refundAmounts?.remainingTermBreakdown)
            setReversalAmount(refundAmounts?.reversalAmount);
            setCancellationIsValid(
                refundAmounts?.amount ||
                refundAmounts?.reversalAmount ||
                refundAmounts?.remainingTermBreakdown
            );
        } else {
            getRefundAmounts({ transactionId })
                .then((res) => {
                    if (res?.status === 'pending') {
                        setCancellationIsValid(true);
                        setBookingStillPending(true);
                    } else {
                        setRefundAmounts(res);
                    }
                }).catch(err => {
                    console.error(err);
                });
        }
    }, [refundAmounts]);

    const onCancelBooking = () => {
        setCancelErrorMessage(undefined);
        // if (!reasonForCancelling) {

        // }
        if (!confirmCancellation) {

        }
        if (confirmCancellation) {
            setCancelInProgress(true);
            beginCancellation({ transactionId })
                .then(() => {
                    location.reload();
                }).catch(err => {
                    setCancelInProgress(false);
                    setCancelErrorMessage('There was an issue cancelling this booking cancellation. Please try again, or contact support.');
                    console.error(err);
                });
        }
    };

    const formatIntegerToMoney = (amount) => {
        if (amount) {
            return formatMoney(intl, new Money(amount, config.currency))
        } else {
            return '';
        }
    };

    if (!cancellationIsValid) {
        return (
            <div className={css.row}>
                <div className={css.col12}>
                    <h2>
                        <FormattedMessage id="RefundBreakdownMaybe.title" />
                    </h2>
                </div>
                <div className={css.col12}>
                    <p>We are unable to cancel this booking.</p>
                </div>
            </div>
        );
    }

    return (
        <div className={css.row}>
            <div className={css.col12}>
                <h2>
                    <FormattedMessage id="RefundBreakdownMaybe.title" />
                </h2>
            </div>
            {/* <div className={css.col12}>
                <h3>
                    <FormattedMessage id="RefundBreakdownMaybe.whyCancel.title" />
                </h3>
            </div>
            <div className={css.col12}>
                <textarea
                    onChange={(e) => setReasonForCancelling(e.target.value)}
                    placeholder={intl.formatMessage({ id: 'RefundBreakdownMaybe.whyCancel.placeholder' })}
                    value={reasonForCancelling}
                />
            </div> */}
            {bookingStillPending &&
                <div className={css.col12}>
                    <p>This booking is still pending, you cancel without penalty</p>
                </div>
            }
            <hr />
            {refundAmounts &&
                <>
                    {(amount || remainingTermBreakdown?.customerTotalAmount) &&
                        <div className={css.col12}>
                            <h3>
                                <FormattedMessage id="RefundBreakdownMaybe.doesThisLookRight.title" />
                            </h3>
                        </div>
                    }
                    <div className={css.col12}>
                        <div className={css.refundBreakdownLineItems}>
                            {(isProvider && reversalAmount) &&
                                < div className={css.lineItem}>
                                    <span className={css.itemLabel}>
                                        <FormattedMessage id="RefundBreakdownMaybe.totalReversalAmount.label" />
                                    </span>
                                    <span className={css.itemValue}>
                                        {formatMoney(intl, new Money(reversalAmount, 'CAD'))}
                                    </span>
                                </div>
                            }
                            {!isProvider &&
                                <>
                                    {amount &&
                                        <>
                                            {installationFee &&
                                                <div className={css.lineItem}>
                                                    <span className={css.itemLabel}>
                                                        <FormattedMessage id="RefundBreakdownMaybe.installationFee.label" />
                                                    </span>
                                                    <span className={css.itemValue}>
                                                        {formatMoney(intl, new Money(installationFee, 'CAD'))}
                                                    </span>
                                                </div>
                                            }
                                            {taxRefundAmount &&
                                                <div className={css.lineItem}>
                                                    <span className={css.itemLabel}>
                                                        <FormattedMessage id="RefundBreakdownMaybe.taxRefundAmount.label" />
                                                    </span>
                                                    <span className={css.itemValue}>
                                                        {formatMoney(intl, new Money(taxRefundAmount, 'CAD'))}
                                                    </span>
                                                </div>
                                            }
                                            <div className={css.lineItem}>
                                                <span className={css.itemLabel}>
                                                    <FormattedMessage id="RefundBreakdownMaybe.totalRefundAmount.label" />
                                                </span>
                                                <span className={css.itemValue}>
                                                    {formatMoney(intl, new Money(amount, 'CAD'))}
                                                </span>
                                            </div>
                                        </>
                                    }
                                    {remainingTermBreakdown?.customerTotalAmount &&
                                        <>
                                            <h4>Remaining Term Balance</h4>
                                            <small><FormattedMessage id="RefundBreakdownMaybe.remainingTermCharge.message" /></small>
                                            <div className={css.totalWrapper}>
                                                <span className={css.itemLabel}>
                                                    <span>
                                                        <FormattedDate value={remainingTermBreakdown.start} {...dateFormatOptions}
                                                        />
                                                    </span>
                                                    <span> - </span>
                                                    <span>
                                                        <FormattedDate value={remainingTermBreakdown.end} {...dateFormatOptions} />
                                                    </span>
                                                    <br />
                                                    <span className={css.subscriptionDateRange}>
                                                        <FormattedMessage
                                                            id="SubscriptionDueLaterLineItems.basePriceBreakdown"
                                                            values={{
                                                                subscriptionBaseAmount: formatIntegerToMoney(remainingTermBreakdown.subscriptionBaseAmount),
                                                                payments: remainingTermBreakdown.remainingSubscriptionPayments,
                                                                seats: remainingTermBreakdown.seats
                                                            }}
                                                        />
                                                    </span>
                                                </span>
                                                <span className={css.itemValue}>
                                                    {formatIntegerToMoney(remainingTermBreakdown?.priceAmount)}
                                                </span>
                                            </div>
                                            {(remainingTermBreakdown.discountAmount && remainingTermBreakdown.discountAmount !== 0) ? (
                                                <div className={css.lineItem}>
                                                    <span className={css.itemLabel}>
                                                        <FormattedMessage id="RefundBreakdownMaybe.remainingTermCharge.discountAmount" />
                                                    </span>
                                                    <span className={css.itemValue}>
                                                        {formatMoney(intl, new Money(remainingTermBreakdown.discountAmount, 'CAD'))}
                                                    </span>
                                                </div>
                                            ) : (null)}
                                            <div className={css.lineItem}>
                                                <span className={css.itemLabel}>
                                                    <FormattedMessage id="RefundBreakdownMaybe.remainingTermCharge.subtotal" />
                                                </span>
                                                <span className={css.itemValue}>
                                                    {formatMoney(intl, new Money(remainingTermBreakdown.subtotal, 'CAD'))}
                                                </span>
                                            </div>
                                            <div className={css.lineItem}>
                                                <span className={css.itemLabel}>
                                                    <FormattedMessage id="RefundBreakdownMaybe.remainingTermCharge.taxAmount" />
                                                </span>
                                                <span className={css.itemValue}>
                                                    {formatMoney(intl, new Money(remainingTermBreakdown.taxAmount, 'CAD'))}
                                                </span>
                                            </div>
                                            <div className={css.lineItem}>
                                                <span className={css.itemLabel}>
                                                    <FormattedMessage id="RefundBreakdownMaybe.remainingTermCharge.customerCommissionAmount" />
                                                </span>
                                                <span className={css.itemValue}>
                                                    {formatMoney(intl, new Money(remainingTermBreakdown.customerCommissionAmount, 'CAD'))}
                                                </span>
                                            </div>
                                            <div className={css.lineItem}>
                                                <span className={css.itemLabel}>
                                                    <FormattedMessage id="RefundBreakdownMaybe.remainingTermCharge.taxOnCustomerCommissionAmount" />
                                                </span>
                                                <span className={css.itemValue}>
                                                    {formatMoney(intl, new Money(remainingTermBreakdown.taxOnCustomerCommissionAmount, 'CAD'))}
                                                </span>
                                            </div>
                                            <div className={css.lineItem}>
                                                <span className={css.itemLabel}>
                                                    <FormattedMessage id="RefundBreakdownMaybe.remainingTermCharge.customerTotalAmount" />
                                                </span>
                                                <span className={css.itemValue}>
                                                    {formatMoney(intl, new Money(remainingTermBreakdown.customerTotalAmount, 'CAD'))}
                                                </span>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                            <div className={css.dividerLine}></div>
                        </div>
                    </div>
                </>
            }
            {cancellationIsValid &&
                <div className={css.col12}>
                    <div className={css.rowUnsetMarginLR}>
                        <div className={css.col05}>
                            <input
                                className={css.confirmCancellationInput}
                                type="checkbox"
                                checked={confirmCancellation}
                                onChange={() => setConfirmCancellation(!confirmCancellation)}
                            />
                        </div>
                        <div className={css.col10}>
                            <p className={css.confirmCancellationText}>
                                <FormattedMessage id="RefundBreakdownMaybe.cancelConfirmation.checkbox" />
                            </p>
                        </div>
                    </div>
                </div>
            }
            <div className={css.col6}>
                {cancellationIsValid &&
                    <Button className={css.cancelButton}
                        inProgress={cancelInProgress}
                        onClick={() => onCancelBooking()}
                        type="button">
                        <FormattedMessage id="RefundBreakdownMaybe.cancel.button" />
                    </Button>
                }
            </div>
            <div className={css.col6}>
                <Button
                    className={css.exitButton}
                    onClick={() => setCancelModalOpen(false)}
                    type="button">
                    <FormattedMessage id="RefundBreakdownMaybe.exit.button" />
                </Button>
            </div>
            {cancelErrorMessage &&
                <div className={css.col12}>
                    <p className={css.cancelErrorMessage}>{cancelErrorMessage}</p>
                </div>
            }
        </div >
    );
});

RefundBreakdownMaybe.defaultProps = {
    setCancelModalOpen: null,
    transactionId: null,
};

RefundBreakdownMaybe.propTypes = {
    setCancelModalOpen: func.isRequired,
    transactionId: string.isRequired,
};

export default RefundBreakdownMaybe;