import config from '../config';
import { types as sdkTypes } from './sdkLoader';

const { UUID, Money } = sdkTypes;

export const getMonthlyPrice = listing => {
  if (!listing?.id?.uuid) {
    return new Money(0, config.currency);
  }

  const subscriptionPrice = listing?.attributes?.publicData?.subscriptionPrice || 0;

  return new Money(subscriptionPrice, config.currency);
};
