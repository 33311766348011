import { bool } from 'prop-types';
import React from 'react';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { txIsCanceled, txIsDeclined, txIsDelivered } from '../../util/transaction';
import { LINE_ITEM_PROVIDER_PAYOUT_TAX_PASSED_OVER, LINE_ITEM_PROVIDER_PAYOUT_TOTAL_PLUS_TAX, propTypes } from '../../util/types';
import css from './BookingBreakdown.module.css';

const LineItemTotalProviderPayout = props => {
  const { transaction, isProvider, intl } = props;

  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsDelivered(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  } else if (txIsDeclined(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  } else if (txIsCanceled(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
  }

  const providerPayoutTaxPassedOver = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_PAYOUT_TAX_PASSED_OVER
  );
  let formattedPayoutTaxPassedOver = null;
  if (providerPayoutTaxPassedOver) {
    formattedPayoutTaxPassedOver = formatMoney(intl, providerPayoutTaxPassedOver.lineTotal);
  }

  const providerPayoutTotalPlusTax = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_PAYOUT_TOTAL_PLUS_TAX
  );
  let formattedPayoutTotalPlusTax = null;
  if (providerPayoutTotalPlusTax) {
    formattedPayoutTotalPlusTax = formatMoney(intl, providerPayoutTotalPlusTax.lineTotal);
  }

  const payoutTotalPrice = isProvider ? transaction.attributes.payoutTotal : null;
  let formattedPayoutTotalPrice = null;
  if (payoutTotalPrice) {
    formattedPayoutTotalPrice = formatMoney(intl, payoutTotalPrice);
  }

  if (!formattedPayoutTotalPrice) {
    return null;
  }

  return (
    <>
      <hr className={css.totalDivider} />
      {formattedPayoutTaxPassedOver && formattedPayoutTotalPlusTax ? (
        <>
          <div className={css.lineItemTotal}>
            <div className={css.itemLabel}>Payout amount</div>
            <div className={css.itemValue}>{formattedPayoutTotalPrice}</div>
          </div>
          <div className={css.lineItemTotal}>
            <div className={css.itemLabel}>Tax passed over</div>
            <div className={css.itemValue}>{formattedPayoutTaxPassedOver}</div>
          </div>
          <hr className={css.totalDivider} />
          <div className={css.lineItemTotal}>
            <div className={css.itemLabel}>Total payout</div>
            <div className={css.totalPrice}>{formattedPayoutTotalPlusTax}</div>
          </div>
        </>
      ) : (
        <div className={css.lineItemTotal}>
          <div className={css.itemLabel}><FormattedMessage id={providerTotalMessageId} /></div>
          <div className={css.totalPrice}>{formattedPayoutTotalPrice}</div>
        </div>
      )}
    </>
  );
};

LineItemTotalProviderPayout.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalProviderPayout;
