import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { DIMENSIONS_FT, DIMENSIONS_INCH, DIMENSIONS_MM, DIMENSIONS_PX, propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelect } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';

import css from './EditListingDescriptionForm.module.scss';

const TITLE_MAX_LENGTH = 70;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        categories,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleTipMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleTipMessage',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionTipMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionTipMessage',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const dimensionsOfSignLabelMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.dimensionsOfSignLabelMessage',
      });
      const dimensionsOfSignPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.dimensionsOfSignPlaceholderMessage',
      });
      const dimensionsOfSignSizeUnits = [
        {
          key: DIMENSIONS_FT,
          label: DIMENSIONS_FT,
        },
        {
          key: DIMENSIONS_INCH,
          label: DIMENSIONS_INCH,
        },
        {
          key: DIMENSIONS_MM,
          label: DIMENSIONS_MM,
        },
        {
          key: DIMENSIONS_PX,
          label: DIMENSIONS_PX,
        },
      ];

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />
          <p className={css.tip}>{titleTipMessage}</p>

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />
          <p className={css.tip}>{descriptionTipMessage}</p>

          <CustomCategorySelectFieldMaybe
            id="category"
            name="category"
            categories={categories}
            intl={intl}
          />

          <label>
            <FormattedMessage id="EditListingDescriptionForm.dimensionsOfSignTitle" />
          </label>
          <div className={css.dimensionContainer}>
            <div className={css.rowUnsetMarginLR}>
              <div className={css.col3}>
                <FieldTextInput
                  className={css.input}
                  id="dimensionsOfSignWidth"
                  name="dimensionsOfSignWidth"
                  type="number"
                  label="Width"
                  schemaType={'long'}
                  min={0}
                />
              </div>
              <div className={css.col3}>
                <FieldTextInput
                  className={css.input}
                  id="dimensionsOfSignHeight"
                  name="dimensionsOfSignHeight"
                  type="number"
                  label="Height"
                  schemaType={'long'}
                  min={0}
                />
              </div>
              <div className={css.col4}>
                <FieldSelect
                  className={css.input}
                  name={'dimensionsOfSignUnit'}
                  id={'dimensionsOfSignUnit'}
                  label={dimensionsOfSignLabelMessage}
                >
                  <option disabled hidden value="">
                    {dimensionsOfSignPlaceholderMessage}
                  </option>
                  {dimensionsOfSignSizeUnits.map(c => (
                    <option key={c.key} value={c.key}>
                      {c.label}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            </div>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
