import css from './CustomOfferPaymentForm.module.scss';
import stripePaymentFormCSS from '../../forms/StripePaymentForm/StripePaymentForm.module.css';
import { bool, func, object, shape } from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { acceptCustomOffer, createSetupIntent, getPaymentMethodsList, withdrawalCustomOffer } from '../../util/api';
import { FormattedMessage, injectIntl } from 'react-intl';
import { pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { CustomStripePaymentForm } from '../../forms';
import { Button, PrimaryButton } from '../../components';
import { LINE_ITEM_DAY } from '../../util/types';
import CustomOfferBreakdownMaybe from '../../forms/BookingDatesForm/CustomOfferBreakdownMaybe';
import CustomSavedCardDetails from '../../components/CustomSavedCardDetails/CustomSavedCardDetails';
import { withRouter } from 'react-router-dom';
import DateTimeUtil from '../../util/datetime.util';

const CustomOfferPaymentForm = withRouter(injectIntl(props => {
    const { customOffer, currentUser, intl, isProvider, listing, history } = props;

    if (!customOffer) return null;

    const transaction = customOffer?.transaction;
    const lineItems = customOffer?.lineItems;

    const { bookingStartDate, bookingEndDate, seats } = customOffer;
    const bookingAttributes = {
        start: bookingStartDate,
        end: bookingEndDate,
        startDate: bookingStartDate,
        endDate: bookingEndDate,
        displayStart: bookingStartDate,
        displayEnd: bookingEndDate,
        seats
    };

    const [showCustomPaymentForm, setCustomPaymentForm] = useState(false);
    const [customClientSecret, setCustomClientSecret] = useState(null);
    const [customDefaultPaymentMethod, setCustomDefaultPaymentMethod] = useState(null);
    const [customPaymentMethodList, setCustomPaymentMethodList] = useState(new Array());

    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [withdrawalInProgress, setWithdrawalInProgress] = useState(false);

    const dispatch = useDispatch();
    const onManageDisableScrolling = (componentId, disableScrolling) => {
        dispatch(manageDisableScrolling(componentId, disableScrolling));
    };

    useEffect(() => {
        if (
            !isProvider &&
            customOffer.state === 'pending' &&
            !showCustomPaymentForm &&
            !customClientSecret &&
            !customDefaultPaymentMethod &&
            customPaymentMethodList.length === 0) {
            getPaymentMethodsList()
                .then(result => {
                    if (result && result.length > 0) {
                        setCustomPaymentMethodList(result);
                        setCustomDefaultPaymentMethod(result[0]);
                        setCustomClientSecret(null);
                        setCustomPaymentForm(false);
                    } else {
                        getCustomClientSecretAndSetForm();
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [
        showCustomPaymentForm, customClientSecret,
        customDefaultPaymentMethod, customPaymentMethodList
    ]);

    const handleCustomPaymentSubmit = (
        stripeCustomerId,
        stripePaymentMethodId,
        stripeSetupIntentId
    ) => {
        setSubmitInProgress(true);
        acceptCustomOffer({
            customOfferId: customOffer.id,
            stripeCustomerId,
            stripePaymentMethodId,
            stripeSetupIntentId,
        }).then(result => {
            history.push(
                pathByRouteName('OrderDetailsPage', routeConfiguration(),
                    { id: result.transactionId }
                )
            );
        }).then(() => {
            window.location.reload();
        }).catch(err => {
            console.error(err);
            setSubmitInProgress(false);
        });
    };

    const deleteCardPaymentMethod = (paymentMethodId) => {
        detachPaymentMethod({ paymentMethodId: paymentMethodId })
            .then(() => {
                getPaymentMethodsList()
                    .then(result => {
                        if (result && result.length > 0) {
                            setCustomClientSecret(null);
                            setCustomPaymentMethodList(result);
                            setCustomDefaultPaymentMethod(result[0]);
                        } else {
                            setCustomPaymentForm(true);
                            setCustomClientSecret(null);
                            setCustomDefaultPaymentMethod(null);
                            setCustomPaymentMethodList([]);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            })
            .catch(error => {
                console.error(error);
            });
    }

    const changeCustomPaymentMethod = (paymentMethod) => {
        setCustomDefaultPaymentMethod(paymentMethod);
        setCustomPaymentForm(false);
    }

    const getCustomClientSecretAndSetForm = () => {
        if (!customClientSecret) {
            createSetupIntent()
                .then(setupIntentId => {
                    setCustomClientSecret(setupIntentId);
                    setCustomPaymentForm(true);
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            setCustomPaymentForm(true);
        }
    }

    const onWithdrawalCustomOffer = () => {
        if (isProvider) {
            setWithdrawalInProgress(true);
            withdrawalCustomOffer({ customOfferId: customOffer.id })
                .then(() => {
                    window.location.reload();
                }).catch(err => {
                    setWithdrawalInProgress(false);
                    console.error(err);
                });
        }
    };

    return (customOffer ? (
        <>
            <div className={css.row}>
                <div className={css.col12}>
                    <h2><FormattedMessage id="CustomOfferPaymentForm.title" /></h2>
                    <span>
                        <strong><FormattedMessage id="CustomOfferPaymentForm.expiryDate" />{'  '}</strong>
                        {DateTimeUtil.formatDate(customOffer.expiry)}
                    </span>
                </div>
                <div className={css.col12}>
                    <div className={css.dividerLine}></div>
                </div>
                <div className={css.col12}>
                    <label><FormattedMessage id="CustomOfferPaymentForm.startDate" /></label>
                    {DateTimeUtil.formatDate(customOffer.bookingStartDate, 'ddd, MMM D, YYYY')}
                </div>
                <div className={css.col12}>
                    <label><FormattedMessage id="CustomOfferPaymentForm.endDate" /></label>
                    {DateTimeUtil.formatDate(customOffer.bookingEndDate, 'ddd, MMM D, YYYY')}
                </div>
                <div className={css.col12}>
                    <label><FormattedMessage id="CustomOfferPaymentForm.offerDescription" /></label>
                    {customOffer.description}
                </div>
                <div className={css.col12}>
                    <div className={css.dividerLine}></div>
                </div>
                <div className={css.priceBreakdownContainer}>
                    {transaction &&
                        <CustomOfferBreakdownMaybe
                            bookingData={{
                                ...bookingAttributes,
                                unitType: LINE_ITEM_DAY
                            }}
                            transaction={transaction}
                            lineItems={lineItems}
                            listing={listing}
                            isProvider={isProvider}
                            userRole={'customer'}
                            isCheckout={true}
                        />
                    }
                </div>
            </div>
            {!isProvider && customOffer.state === 'pending' ? (
                <div className={css.row}>
                    <div className={css.col12}>
                        <h6><FormattedMessage id="CustomOfferPaymentForm.paymentDetails" /></h6>
                    </div>
                    <div className={css.col12}>
                        {(customDefaultPaymentMethod && customPaymentMethodList) &&
                            <>
                                <CustomSavedCardDetails
                                    onManageDisableScrolling={onManageDisableScrolling}
                                    customDefaultPaymentMethod={customDefaultPaymentMethod}
                                    customPaymentMethodList={customPaymentMethodList}
                                    onChange={getCustomClientSecretAndSetForm}
                                    // onDeleteCardPaymentMethod={deleteCardPaymentMethod}
                                    changeCustomPaymentMethod={changeCustomPaymentMethod}
                                />
                                {!showCustomPaymentForm &&
                                    <>
                                        <br />
                                        <PrimaryButton
                                            className={stripePaymentFormCSS.submitButton}
                                            type="button"
                                            inProgress={submitInProgress}
                                            onClick={() => handleCustomPaymentSubmit(null, customDefaultPaymentMethod.id, null, null)}>
                                            <FormattedMessage id="StripePaymentForm.submitPaymentInfo" />
                                        </PrimaryButton>
                                        <br />
                                    </>
                                }
                            </>
                        }
                        {(showCustomPaymentForm && customClientSecret) &&
                            <>
                                <CustomStripePaymentForm
                                    clientSecret={customClientSecret}
                                    ctaButtonTxt="Submit payment"
                                    handleCustomPaymentSubmit={handleCustomPaymentSubmit}
                                    currentUserEmail={currentUser.attributes.email}
                                    showInitialMessageInput={false}
                                    trxSubmitInProgress={submitInProgress}
                                />
                            </>
                        }
                    </div>
                </div>
            ) : (
                customOffer.state !== 'pending' && (
                    <div className={css.row}>
                        <div className={css.col12}>
                            {customOffer.state === 'accepted' ? (
                                <p className={css.customOfferAccepted}>
                                    <FormattedMessage id="CustomOfferPaymentForm.acceptedMessage" />
                                </p>
                            ) : (
                                <p className={css.customOfferNotAvailable}>
                                    <FormattedMessage id="CustomOfferPaymentForm.notAvailableMessage" />
                                </p>
                            )}
                        </div>
                    </div>
                )
            )}

            {isProvider && customOffer.state === 'pending' && (
                <Button
                    className={css.withdrawalOffer}
                    type="button"
                    inProgress={withdrawalInProgress}
                    onClick={() => onWithdrawalCustomOffer()}>
                    <FormattedMessage id="CustomOfferPaymentForm.withdrawalOffer" />
                </Button>
            )}
        </>
    ) : (null)
    );
}));

CustomOfferPaymentForm.propTypes = {
    customOffer: object.isRequired,
    currentUser: object.isRequired,
    isProvider: bool.isRequired,

    history: shape({
        push: func.isRequired,
    }).isRequired,
};

export default CustomOfferPaymentForm;