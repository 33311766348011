import React, { useState } from 'react';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { LINE_ITEM_TAX_ON_PROVIDER_COMMISSION, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemTaxOnProviderFee = props => {
  const { transaction, intl } = props;

  const taxLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_TAX_ON_PROVIDER_COMMISSION
  );

  const [taxTotal] = useState(taxLineItem ? taxLineItem : null);

  return taxTotal ? (
    <div className={css.lineItem}>
      <div className={css.itemLabel}>
        <FormattedMessage id="LineItemTaxOnProviderFee.taxLabel" />
      </div>
      <div className={css.itemValue}>{formatMoney(intl, taxTotal.lineTotal)}</div>
    </div>
  ) : null;
};

LineItemTaxOnProviderFee.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTaxOnProviderFee;
