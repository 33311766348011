import React, { useState } from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_INSTALLATION_FEE, propTypes } from '../../util/types';
import subscriptionCss from './subscription/Subscription.module.scss';
import css from './BookingBreakdown.module.css';

const LineItemInstallationFee = props => {
  const { transaction, intl } = props;

  const installationFeeLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_INSTALLATION_FEE
  );

  if (!installationFeeLineItem) {
    return null;
  }

  const [installationFee] = useState(installationFeeLineItem ? installationFeeLineItem : null);
  const quantity = parseInt(installationFee.quantity);
  const formattedLineTotal = formatMoney(intl, installationFee.lineTotal)

  return formattedLineTotal ? (
    <>
      <div className={subscriptionCss.basePriceLabelWrapper}>
        <p>
          <FormattedMessage
            id="LineItemInstallationFee.label"
          />
        </p>
      </div>
      <div className={css.lineItem}>
        <div className={css.itemLabel}>
          <FormattedMessage
            id="LineItemInstallationFee.priceXQty"
            values={{
              unitPrice: formatMoney(intl, installationFee.unitPrice),
              quantity
            }}
          />
        </div>
        <div className={css.itemValue}>{formattedLineTotal}</div>
      </div>
    </>
  ) : null;
};

LineItemInstallationFee.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemInstallationFee;
