import { useRef, useState } from 'react';
import css from './UpdatePaymentMethodMaybe.module.scss';
import { useSelector } from 'react-redux';
import IconCard from '../IconCard/IconCard';
import Modal from '../Modal/Modal';
import UpdateBookingPaymentMethodForm from '../../containers/UpdateBookingPaymentMethodForm/UpdateBookingPaymentMethodForm';
import { FormattedMessage } from 'react-intl';

const UpdatePaymentMethodMaybe = props => {
    const { onManageDisableScrolling, transactionId } = props;

    const updatePaymentMethodFormRef = useRef(null);
    const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);

    const { paymentMethod, paymentMethodUpdateRequired } = useSelector(state => state.TransactionPage);
    const card = paymentMethod?.card;

    if (!card) {
        return null;
    }

    return (
        <div className={css.container}>
            <div className={css.innerContainer}>
                <div className={css.rowUnsetMarginLR}>
                    <div className={css.col12}>
                        <h6>
                            <FormattedMessage id="UpdatePaymentMethodMaybe.title" />
                        </h6>
                    </div>
                    <div className={css.col12}>
                        <IconCard brand={card.brand} className={css.cardIcon} />
                        <FormattedMessage 
                            id="UpdatePaymentMethodMaybe.card.details" 
                            values={{
                                brand: card.brand,
                                last4: card.last4
                            }}
                        />
                    </div>
                    <div className={css.col12}>
                        <span className={css.expiry}>
                            <strong>
                                <FormattedMessage id="UpdatePaymentMethodMaybe.card.expiry" />
                            </strong>
                            {card.exp_month}/{card.exp_year}
                        </span>
                    </div>
                    <div className={css.col12}>
                        <a onClick={() => {
                            updatePaymentMethodFormRef.current.fetchStripeClientSecret();
                            setPaymentMethodModalOpen(true);
                        }}>
                            <FormattedMessage id="UpdatePaymentMethodMaybe.card.update" />
                        </a>
                    </div>
                    {paymentMethodUpdateRequired &&
                        <div className={css.col12}>
                            <span className={css.actionRequiredText}>
                                <FormattedMessage id="UpdatePaymentMethodMaybe.actionRequired.message" />
                            </span>
                        </div>
                    }
                </div>
                <Modal
                    id="UpdatePaymentMethodMaybe.modal"
                    isOpen={paymentMethodModalOpen}
                    onClose={() => {
                        setPaymentMethodModalOpen(false);
                    }}
                    usePortal={true}
                    onManageDisableScrolling={onManageDisableScrolling}>
                    <UpdateBookingPaymentMethodForm
                        ref={updatePaymentMethodFormRef}
                        transactionId={transactionId}
                        setPaymentMethodModalOpen={setPaymentMethodModalOpen}
                    />
                </Modal>
            </div>
        </div>
    );
};

export default UpdatePaymentMethodMaybe;
