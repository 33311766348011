import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldTextInput,
  Map,
} from '../../components';

import css from './EditListingLocationForm.module.css';

import { types as sdkTypes } from '../../util/sdkLoader';

const identity = v => v;

const { LatLng } = sdkTypes;

if (typeof window !== 'undefined') {
  window.$lat = ''; //global variable
  window.$lng = '';
}

class WithMarker extends React.Component {
  constructor(props) {
    super(props);

    const initialValue =
      this.props.initialValues?.latitude && this.props.initialValues?.longitude
        ? new LatLng(this.props.initialValues?.latitude, this.props.initialValues?.longitude)
        : new LatLng(51.482577, -0.118105);

    this.props1 = {
      center: initialValue,
      obfuscatedCenter: initialValue,
      address: 'address',
      zoom: 15,
      mapsConfig: {
        ...config.maps,
        customMarker: {
          ...config.maps.customMarker,
          draggable: true,
        },
      },
    };
  }

  render() {
    return (
      <div style={{ height: 400 }}>
        <Map {...this.props1} />
      </div>
    );
  }
}

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{
      // expect (field, value) args from the mutator
      setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value);
      },
    }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        initialValues,
        form: {
          mutators: { setValue },
        },
        values,
      } = formRenderProps;

      // put the reference on a window variable of your choice here
      if (!window.setFormValue) window.setFormValue = form.mutators.setValue;

      const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const optionalText = intl.formatMessage({
        id: 'EditListingLocationForm.optionalText',
      });

      const buildingMessage = intl.formatMessage(
        { id: 'EditListingLocationForm.building' },
        { optionalText: optionalText }
      );
      const buildingPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.buildingPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          {/* <FormattedMessage id="EditListingLocationForm.showListingFailed" /> */}
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          {/* <LocationAutocompleteInputField
            className={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            autoFocus
            name="location"
            label={titleRequiredMessage}
            placeholder={addressPlaceholderMessage}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompleteSearchRequired(addressRequiredMessage),
              autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
            
          />

       
          <FieldTextInput
            className={css.building}
            type="text"
            name="building"
            id="building"
            label={buildingMessage}
            placeholder={buildingPlaceholderMessage}
          />
        */}

          <FieldTextInput
            className={css.building}
            type="text"
            name="address"
            id="address"
            label="Address"
            placeholder="Address"
            disabled={true}
          />

          {values.address ? <p className={css.info2}>Address: {values.address}</p> : null}
          <p className={css.info}>ℹ️ Drag the pin to set your location</p>

          <WithMarker initialValues={initialValues} />

          <FieldTextInput
            className={css.latitude}
            type="text"
            name="latitude"
            id="latitude"
            label="Latitude"
            placeholder="Latitude"
            // disabled={true}
          />

          <FieldTextInput
            className={css.longitude}
            type="text"
            name="longitude"
            id="longitude"
            label="Longitude"
            placeholder="Longitude"
            // disabled={true}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
