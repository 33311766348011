import React, { useState } from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_DISCOUNT, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemDiscountMaybe = props => {
  const { transaction, intl } = props;

  const discountLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_DISCOUNT
  );

  const [discount] = useState(discountLineItem ? discountLineItem : null);

  return discount ? (
    <div className={css.lineItem}>
      <div className={css.itemLabel}>
        <FormattedMessage
          id="LineItemDiscountMaybe.label"
          values={{ discount: +Math.abs(parseInt(discount.percentage)) }}
        />
      </div>
      <div className={css.itemValue}>{formatMoney(intl, discount.lineTotal)}</div>
    </div>
  ) : null;
};

LineItemDiscountMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDiscountMaybe;
