import ClientAccountingUtil from '../../../util/client-accouting.util';
import { daysBetween } from '../../../util/dates';
import DateTimeUtil from '../../../util/datetime.util';
import { CUSTOMER_COMMISSION_PERCENTAGE, LINE_ITEM_SUBSCRIPTION_FIRST_MONTH, LINE_ITEM_SUBTOTAL, PROVIDER_COMMISSION_PERCENTAGE, SUBSCRIPTION_MONTH_DAYS_LIMIT, TAX_RATE_HST } from '../../../util/types';
import { Decimal } from 'decimal.js';
import moment from 'moment';

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.removeDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
};

export const getDateLabel = date => {
  const workingDate = date.getDate();
  switch (workingDate) {
    case 1:
      return workingDate + 'st';
    case 2:
      return workingDate + 'nd';
    case 3:
      return workingDate + 'rd';
    default:
      return workingDate + 'th';
  }
};

export const getSubscriptionIntervals = (
  start,
  end,
  transaction,
  bookingQtyAtleast1Month,
  listing,
  subscriptionInstallationFee = 0,
  discountPercentage = null,
) => {
  if (bookingQtyAtleast1Month) {
    // console.log(`****************************************`);
    // console.log(`dt getSubscriptionIntervals start`, start);
    // console.log(`dt getSubscriptionIntervals end`, end);
    // console.log(`getSubscriptionIntervals transaction`, transaction);
    // console.log(`getSubscriptionIntervals bookingQtyAtleast1Month`, bookingQtyAtleast1Month);
    // console.log(`getSubscriptionIntervals unitType`, unitType);
    // console.log(`getSubscriptionIntervals listing`, listing);

    const unitPurchase = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_SUBSCRIPTION_FIRST_MONTH
    );

    const seatsQuantity = unitPurchase?.quantity ? unitPurchase.quantity : 1;

    const subscriptionPayments = [];

    let monthCount = 0;
    let testingEndDate = DateTimeUtil.convertToUTCNoonDate(start).toDate()

    while (testingEndDate < end) {
      //keep setting the interval start date to the next month on the same date
      const currentStartDate = DateTimeUtil.dateAdd(DateTimeUtil.convertToUTCNoonDate(start).toDate(), monthCount, 'months').toDate()

      //set the interval end date to the second next month on the (currentStartDate date - 1)
      monthCount = monthCount + 1;

      const testEndDate = DateTimeUtil.dateAdd(DateTimeUtil.convertToUTCNoonDate(start).toDate(), monthCount, 'months').toDate()

      //if the calculated end date is greater than the booking end date, that means this is the last interval, so end date = booking end date
      const currentEndDate = testEndDate > end ? end : testEndDate;

      //because of the way ST calculates the booking, the value of end date is one day greater than the label for it
      // const displayStartDate = currentEndDate.addDays(1);
      // const displayEndDate = currentEndDate.removeDays(1);

      const daysCount = daysBetween(
        currentStartDate,
        currentEndDate //end date comes with one hour less than the start date, so we need to adjust
        //so we can get the correct days count
      );
      const daysCountLabel = daysCount === 1 ? daysCount + ' day' : daysCount + ' days';

      const unitPriceAmount = unitPurchase ? unitPurchase.unitPrice.amount : 0;

      //TODO remove if you want to calculate price according to daily price
      const listingObj = listing || transaction?.listing;
      const listingSubscriptionAmount = listingObj?.attributes?.publicData?.subscriptionPrice;
      // ---- end

      const priceAmount = listingSubscriptionAmount
        ? seatsQuantity * listingSubscriptionAmount
        : daysCount * seatsQuantity * unitPriceAmount;

      let discountAmount = 0;
      if (discountPercentage) {
        discountAmount = priceAmount * (discountPercentage / 100);
      }

      let installationFeeAmount = 0;
      if (monthCount === 1 && subscriptionInstallationFee > 0) {
        installationFeeAmount = subscriptionInstallationFee;
      }

      let subtotal = priceAmount;
      if (discountAmount) {
        subtotal = subtotal + discountAmount;
      }
      if (installationFeeAmount) {
        subtotal = subtotal + installationFeeAmount;
      }

      const authorPublicData = listing?.author?.attributes?.profile?.publicData;
      const finalProviderCommission = authorPublicData?.customProviderCommission ?
        ClientAccountingUtil.convertToNegativeNumber(authorPublicData.customProviderCommission)
        : PROVIDER_COMMISSION_PERCENTAGE;
      const providerCommissionAmount = subtotal * (finalProviderCommission / 100);
      const taxOnProviderCommissionAmount = providerCommissionAmount * TAX_RATE_HST;

      const customerCommissionAmount = subtotal * (CUSTOMER_COMMISSION_PERCENTAGE / 100);
      const taxOnCustomerCommissionAmount = customerCommissionAmount * TAX_RATE_HST;

      const taxAmount = subtotal * TAX_RATE_HST;
      const subtotalPlusTax = subtotal + taxAmount;

      const customerTotalAmount = subtotal + taxAmount + customerCommissionAmount + taxOnCustomerCommissionAmount;
      const providerPayoutLessTaxAmount = subtotal + (providerCommissionAmount + taxOnProviderCommissionAmount);

      const discountPercentagePositive = discountPercentage ? ClientAccountingUtil.convertToPositiveNumber(discountPercentage) : null;

      testingEndDate = currentEndDate;

      subscriptionPayments.push({
        startLabel: currentStartDate,
        start: currentStartDate,
        endLabel: currentEndDate,
        end: currentEndDate,
        daysCount,
        daysCountLabel,
        priceAmount,
        discountAmount,
        installationFeeAmount,
        subtotal,
        taxAmount,
        subtotalPlusTax,
        customerCommissionAmount,
        taxOnCustomerCommissionAmount,
        customerTotalAmount,
        providerCommissionAmount,
        taxOnProviderCommissionAmount,
        providerPayoutLessTaxAmount,
        discountPercentage: discountPercentagePositive
      });
    }

    const residualMonthData = residualInterval(start, end)
    if (residualMonthData?.subscriptionFirstMonth) {
      if (subscriptionPayments[0]?.installationFeeAmount <= 0) {
        subscriptionPayments.shift();
      }
    }
    if (residualMonthData?.residualMonth) {
      subscriptionPayments.pop();
    }

    return subscriptionPayments;
  } else {
    return [];
  }
};

export const getTotalForDueLater = (
  transaction,
  bookingQtyAtleast1Month,
  listing,
  currentSubscriptionPayments = null,
  subscriptionInstallationFee = null,
  discountPercentage = null,
) => {
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_SUBSCRIPTION_FIRST_MONTH
  );

  const quantity = unitPurchase ? parseInt(unitPurchase.quantity) : 1;
  const seatsQuantity = quantity;

  let subscriptionPayments = [];
  if (currentSubscriptionPayments) {
    subscriptionPayments = currentSubscriptionPayments;
  } else {
    const { start, end } = transaction.booking.attributes;
    subscriptionPayments = getSubscriptionIntervals(
      start,
      end,
      transaction,
      bookingQtyAtleast1Month,
      listing
    );
  }

  const currentListing = listing || transaction?.listing;
  const listingSubscriptionAmount = currentListing?.attributes?.publicData?.subscriptionPrice;
  const subsMonthsNumber = subscriptionPayments.length;

  const subscriptionMonthsTotalAmountLessTax = (listingSubscriptionAmount * subsMonthsNumber * seatsQuantity);

  let discountAmount = 0;
  if (discountPercentage) {
    discountAmount = subscriptionMonthsTotalAmountLessTax * (discountPercentage / 100);
  }

  let subtotal = subscriptionMonthsTotalAmountLessTax;
  if (discountAmount) {
    subtotal = subtotal + discountAmount;
  }
  const installationFeeAmount = subscriptionInstallationFee ? subscriptionInstallationFee : 0;
  if (installationFeeAmount) {
    subtotal = subtotal + installationFeeAmount;
  }

  const taxAmount = subtotal * TAX_RATE_HST;
  const subtotalPlusTax = subtotal + taxAmount;

  const subscriptionMonthsTotalAmount = subtotal * (1 + TAX_RATE_HST);

  const authorPublicData = listing.author.attributes.profile.publicData;
  const finalProviderCommission = authorPublicData?.customProviderCommission ?
    ClientAccountingUtil.convertToNegativeNumber(authorPublicData.customProviderCommission)
    : PROVIDER_COMMISSION_PERCENTAGE;
  const subscriptionMonthsTotalAmountForProvider = subscriptionMonthsTotalAmount * (1 - finalProviderCommission / 100);
  const subscriptionTotalFeeAmountForProvider = subtotal * (finalProviderCommission / 100);
  const taxOnProviderCommission = subscriptionTotalFeeAmountForProvider * TAX_RATE_HST;

  const subscriptionMonthsTotalAmountForCustomer = subscriptionMonthsTotalAmount * (1 + CUSTOMER_COMMISSION_PERCENTAGE / 100);
  const subscriptionTotalFeeAmountForCustomer = subtotal * (CUSTOMER_COMMISSION_PERCENTAGE / 100);
  const taxOnCustomerCommission = subscriptionTotalFeeAmountForCustomer * TAX_RATE_HST;

  const discountPercentagePositive = discountPercentage ? ClientAccountingUtil.convertToPositiveNumber(discountPercentage) : null;

  const providerPayoutLessTaxAmount = subtotal + (subscriptionTotalFeeAmountForProvider + taxOnProviderCommission);

  return {
    amount: subscriptionMonthsTotalAmount,
    amountLessTax: subscriptionMonthsTotalAmountLessTax,
    discountAmount,
    installationFeeAmount,
    subtotal,
    taxAmount,
    subtotalPlusTax,
    providerAmount: subscriptionMonthsTotalAmountForProvider,
    providerFeeAmount: subscriptionTotalFeeAmountForProvider,
    providerPayoutLessTaxAmount,
    taxOnProviderCommission,
    customerAmount: subscriptionMonthsTotalAmountForCustomer,
    customerFeeAmount: subscriptionTotalFeeAmountForCustomer,
    taxOnCustomerCommission,
    subscriptionBaseAmount: listingSubscriptionAmount,
    seats: seatsQuantity,
    discountPercentage: discountPercentagePositive
  };
};

export const residualInterval = (start, end) => {
  // console.log(`dt residualInterval start`, start);
  // console.log(`dt residualInterval end`, end);
  const bookingQtyAtleast1Month = getBookingQtyAtleast1Month(start, end)
  if (bookingQtyAtleast1Month) {
    const subscriptionPayments = [];

    let monthCount = 0;
    let testingEndDate = DateTimeUtil.convertToUTCNoonDate(start).toDate()

    while (testingEndDate < end) {
      //keep setting the interval start date to the next month on the same date
      const currentStartDate = DateTimeUtil.dateAdd(DateTimeUtil.convertToUTCNoonDate(start).toDate(), monthCount, 'months').toDate()

      //set the interval end date to the second next month on the (currentStartDate date - 1)
      monthCount = monthCount + 1;

      const testEndDate = DateTimeUtil.dateAdd(DateTimeUtil.convertToUTCNoonDate(start).toDate(), monthCount, 'months').toDate()

      //if the calculated end date is greater than the booking end date, that means this is the last interval, so end date = booking end date
      const currentEndDate = testEndDate > end ? end : testEndDate;

      //calculate days count
      const daysCount = daysBetween(currentStartDate, currentEndDate);

      testingEndDate = currentEndDate;

      subscriptionPayments.push({
        start: currentStartDate,
        end: currentEndDate,
        daysCount,
      });
    }

    const residualMonth = getResidualMonthObj(subscriptionPayments, end);
    if (residualMonth) {
      monthCount = monthCount - 1;
    }
    // console.log(`residualInterval residualMonth`, residualMonth)
    // console.log(`residualInterval monthCount (1)`, monthCount)
    // console.log(`residualInterval monthCount (2)`, monthCount)

    const firstSubscriptionMonth = subscriptionPayments[0];

    return {
      residualMonth,
      subscriptionFirstMonth: {
        start,
        end: moment(firstSubscriptionMonth.start).add(1, 'months').toDate()
      },
      monthCount
    };
  } else {
    const bookingQuantity = daysBetween(start, end);
    const baseDayPrice = {
      daysCount: bookingQuantity,
      start,
      end
    }
    return { baseDayPrice };
  }
};

export const getSubtotalLineItem = subtotalMoneyAmount => {
  return {
    code: LINE_ITEM_SUBTOTAL,
    includeFor: ['customer', 'provider'],
    unitPrice: subtotalMoneyAmount,
    lineTotal: subtotalMoneyAmount,
    quantity: new Decimal(1),
    reversal: false
  };
};

export const getMonthCount = (start, end) => {
  const monthCount = moment(end).diff(start, 'month', true);
  return monthCount;
}

export const getBookingQtyAtleast1Month = (start, end) => {
  const bookingQtyMonthCount = getMonthCount(start, end);
  const bookingQtyAtleast1Month = bookingQtyMonthCount > 1;
  return bookingQtyAtleast1Month
}

const getResidualMonthObj = (subscriptionPayments, end) => {
  const lastSubscriptionMonth =
    subscriptionPayments.length > 0 && subscriptionPayments[subscriptionPayments.length - 1];

  const serverDayStart = DateTimeUtil.convertToUTCNoonDate(lastSubscriptionMonth.start).toDate();
  const serverDayEnd = DateTimeUtil.convertToUTCNoonDate(end).toDate();

  lastSubscriptionMonth.start = serverDayStart
  lastSubscriptionMonth.end = serverDayEnd;
  lastSubscriptionMonth.daysCount = daysBetween(lastSubscriptionMonth.start, end)

  // console.log(`lastSubscriptionMonth.start`, moment(lastSubscriptionMonth.start).format('LLLL'))
  // console.log(`lastSubscriptionMonth.end (original)`, moment(end).format('LLLL'))
  // console.log(`lastSubscriptionMonth.end`, moment(lastSubscriptionMonth.end).format('LLLL'))
  // console.log(`lastSubscriptionMonth`, lastSubscriptionMonth)

  const totalResidualMonthsCount = moment.utc(lastSubscriptionMonth.end).diff(lastSubscriptionMonth.start, 'month', true);
  // console.log(`totalResidualMonthsCount`, totalResidualMonthsCount)
  if (totalResidualMonthsCount < 1) {
    return lastSubscriptionMonth
  } else {
    return null
  }
}