import { FormattedDate, FormattedMessage } from 'react-intl';
import { formatMoney } from '../../../util/currency';
import bookingBreakdownCss from '../BookingBreakdown.module.css';
import css from './LineItemsRemainingTermBreakdownMaybe.module.scss';
import config from '../../../config';
import dateFormatOptions from '../../../util/dateFormatOptions';

const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

const LineItemsRemainingTermBreakdownMaybe = (props) => {
    const { transaction, intl, isProvider } = props;
    const remainingTermBreakdown = transaction.attributes?.remainingTermBreakdown;

    if (!remainingTermBreakdown) {
        return null;
    }

    const formatIntegerToMoney = (amount) => {
        if (amount) {
            return formatMoney(intl, new Money(amount, config.currency))
        }
        return '--';
    };

    return (
        <>
            <br />
            <div className={css.dueLaterWrapper}>
                <hr className={css.totalDivider} />
                <h2>
                    <FormattedMessage id="LineItemsRemainingTermBreakdownMaybe.title" />
                </h2>
                <div className={css.totalWrapper}>
                    <span className={css.itemLabel}>
                        <span>
                            <FormattedDate value={remainingTermBreakdown.start} {...dateFormatOptions}
                            />
                        </span>
                        <span> - </span>
                        <span>
                            <FormattedDate value={remainingTermBreakdown.end} {...dateFormatOptions} />
                        </span>
                        <br />
                        <span className={css.subscriptionDateRange}>
                            <FormattedMessage
                                id="SubscriptionDueLaterLineItems.basePriceBreakdown"
                                values={{
                                    subscriptionBaseAmount: formatIntegerToMoney(remainingTermBreakdown.subscriptionBaseAmount),
                                    payments: remainingTermBreakdown.remainingSubscriptionPayments,
                                    seats: remainingTermBreakdown.seats
                                }}
                            />
                        </span>
                    </span>
                    <span className={css.itemValue}>
                        {formatIntegerToMoney(remainingTermBreakdown?.priceAmount)}
                    </span>
                </div>
                {remainingTermBreakdown?.discountAmount && remainingTermBreakdown?.discountAmount !== 0 ? (
                    <div className={bookingBreakdownCss.lineItem}>
                        <span className={bookingBreakdownCss.itemLabel}>
                            <FormattedMessage id="LineItemsRemainingTermBreakdownMaybe.discount.label" />
                        </span>
                        <span className={bookingBreakdownCss.itemValue}>
                            {formatIntegerToMoney(remainingTermBreakdown.discountAmount)}
                        </span>
                    </div>
                ) : (null)}
                {remainingTermBreakdown?.installationFeeAmount && remainingTermBreakdown?.installationFeeAmount !== 0 ? (
                    <div className={bookingBreakdownCss.lineItem}>
                        <span className={bookingBreakdownCss.itemLabel}>
                            <FormattedMessage id="LineItemsRemainingTermBreakdownMaybe.installationFee.label" />
                        </span>
                        <span className={bookingBreakdownCss.itemValue}>
                            {formatIntegerToMoney(remainingTermBreakdown.installationFeeAmount)}
                        </span>
                    </div>
                ) : (null)}
                <div className={bookingBreakdownCss.lineItem}>
                    <span className={bookingBreakdownCss.itemLabel}>
                        <FormattedMessage id="LineItemsRemainingTermBreakdownMaybe.subtotal.label" />
                    </span>
                    <span className={bookingBreakdownCss.itemValue}>
                        {formatIntegerToMoney(remainingTermBreakdown?.subtotal)}
                    </span>
                </div>
                <div className={bookingBreakdownCss.lineItem}>
                    <span className={bookingBreakdownCss.itemLabel}>
                        <FormattedMessage id="LineItemsRemainingTermBreakdownMaybe.tax.label" />
                    </span>
                    <span className={bookingBreakdownCss.itemValue}>
                        {formatIntegerToMoney(remainingTermBreakdown?.taxAmount)}
                    </span>
                </div>
                {!isProvider &&
                    <>
                        <div className={bookingBreakdownCss.lineItem}>
                            <span className={bookingBreakdownCss.itemLabel}>
                                <FormattedMessage id="LineItemsRemainingTermBreakdownMaybe.adloFee.label" />
                            </span>
                            <span className={bookingBreakdownCss.itemValue}>
                                {formatIntegerToMoney(remainingTermBreakdown?.customerCommissionAmount)}
                            </span>
                        </div>
                        <div className={bookingBreakdownCss.lineItem}>
                            <span className={bookingBreakdownCss.itemLabel}>
                                <FormattedMessage id="LineItemsRemainingTermBreakdownMaybe.taxOnAdloFee.label" />
                            </span>
                            <span className={bookingBreakdownCss.itemValue}>
                                {formatIntegerToMoney(remainingTermBreakdown?.taxOnCustomerCommissionAmount)}
                            </span>
                        </div>
                        <hr className={bookingBreakdownCss.totalDivider} />
                        <div className={bookingBreakdownCss.lineItemTotal}>
                            <span className={bookingBreakdownCss.totalLabel}>
                                <FormattedMessage id="LineItemsRemainingTermBreakdownMaybe.totalCharges.label" />
                            </span>
                            <span className={bookingBreakdownCss.totalPrice}>
                                {formatIntegerToMoney(remainingTermBreakdown?.customerTotalAmount)}
                            </span>
                        </div>
                        <hr className={bookingBreakdownCss.totalDivider} />
                    </>
                }
                {isProvider &&
                    <>
                        <hr className={bookingBreakdownCss.totalDivider} />
                        <div className={bookingBreakdownCss.lineItemTotal}>
                            <span className={bookingBreakdownCss.totalLabel}>
                                <FormattedMessage id="LineItemsRemainingTermBreakdownMaybe.subtotalPlusTax.label" />
                            </span>
                            <span className={bookingBreakdownCss.totalPrice}>
                                {formatIntegerToMoney(remainingTermBreakdown?.subtotalPlusTax)}
                            </span>
                        </div>
                        <hr className={bookingBreakdownCss.totalDivider} />
                    </>
                }
                {isProvider &&
                    <div className={css.payoutBreakdown}>
                        <h6>Payout breakdown</h6>
                        {(remainingTermBreakdown?.taxOnBookingDeductedFromProviderPayout && remainingTermBreakdown?.taxOnBookingDeductedFromProviderPayout !== 0) ?
                            <div className={bookingBreakdownCss.lineItem}>
                                <span className={bookingBreakdownCss.itemLabel}>
                                    <FormattedMessage id="LineItemsRemainingTermBreakdownMaybe.taxOnBookingDeductedFromProviderPayout.label" />
                                </span>
                                <span className={bookingBreakdownCss.itemValue}>
                                    {formatIntegerToMoney(remainingTermBreakdown.taxOnBookingDeductedFromProviderPayout)}
                                </span>
                            </div>
                            : (null)}
                        <div className={bookingBreakdownCss.lineItem}>
                            <span className={bookingBreakdownCss.itemLabel}>
                                <FormattedMessage id="LineItemsRemainingTermBreakdownMaybe.adloFee.label" />
                            </span>
                            <span className={bookingBreakdownCss.itemValue}>
                                {formatIntegerToMoney(remainingTermBreakdown?.providerCommissionAmount)}
                            </span>
                        </div>
                        <div className={bookingBreakdownCss.lineItem}>
                            <span className={bookingBreakdownCss.itemLabel}>
                                <FormattedMessage id="LineItemsRemainingTermBreakdownMaybe.taxOnAdloFee.label" />
                            </span>
                            <span className={bookingBreakdownCss.itemValue}>
                                {formatIntegerToMoney(remainingTermBreakdown?.taxOnProviderCommissionAmount)}
                            </span>
                        </div>
                        <hr className={bookingBreakdownCss.totalDivider} />
                        <div className={bookingBreakdownCss.lineItemTotal}>
                            <span className={bookingBreakdownCss.totalLabel}>
                                <FormattedMessage id="LineItemsRemainingTermBreakdownMaybe.totalPayout.label" />
                            </span>
                            <span className={bookingBreakdownCss.totalPrice}>
                                {formatIntegerToMoney(remainingTermBreakdown?.payoutTotal)}
                            </span>
                        </div>
                        <hr className={bookingBreakdownCss.totalDivider} />
                    </div>
                }
            </div>
        </>
    );
};

export default LineItemsRemainingTermBreakdownMaybe;