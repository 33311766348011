export const maximumCommonNumberOfSeats = (startDate, endDate, timeSlots) => {
  //we create new objects to avoid changing the values of the actual dates
  const testStartDate = new Date(startDate);
  const testEndDate = new Date(endDate);

  //set end date hours to 0 to avoid counting one extra timeslot
  testEndDate.setHours(0);

  const selectedTimeSlots = timeSlots
    .filter(t => t)
    .filter(t => {
      const timeSlotStart = t.attributes.start;
      const timeSlotEnd = t.attributes.end;
      const startDateSlot =
        timeSlotStart.getTime() < testStartDate.getTime() &&
        timeSlotEnd.getTime() > testStartDate.getTime();
      const inBetweenSlots =
        timeSlotStart.getTime() > testStartDate.getTime() &&
        timeSlotEnd.getTime() > testStartDate.getTime() &&
        timeSlotStart.getTime() < testEndDate.getTime() &&
        timeSlotEnd.getTime() < testEndDate.getTime();
      const endDateSlot =
        timeSlotStart.getTime() < testEndDate.getTime() &&
        timeSlotEnd.getTime() > testEndDate.getTime();

      return startDateSlot || inBetweenSlots || endDateSlot;
    });

  const seatsArray = selectedTimeSlots.map(t => t.attributes.seats);
  const maximumNumberOfSeats = seatsArray.reduce((a, b) => Math.min(a, b));

  return maximumNumberOfSeats;
};
