import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldTextInput, SecondaryButton, Modal } from '../../components';
import { propTypes } from '../../util/types';
import css from './SendMessageForm.module.scss';
import { PickerOverlay } from 'filestack-react';
import { saveArtworkFiles } from '../../util/api';
import { loadArtworkFilesAndMessages, loadCustomOffersAndMessages } from '../../containers/TransactionPage/TransactionPage.duck';
import CustomOfferForm from '../../components/CustomOfferForm/CustomOfferForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BLUR_TIMEOUT_MS = 100;

const IconSendMessage = () => {
  return (
    <svg
      className={css.sendIcon}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={css.strokeMatter} fill="none" fillRule="evenodd" strokeLinejoin="round">
        <path d="M12.91 1L0 7.003l5.052 2.212z" />
        <path d="M10.75 11.686L5.042 9.222l7.928-8.198z" />
        <path d="M5.417 8.583v4.695l2.273-2.852" />
      </g>
    </svg>
  );
};

class SendMessageFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { showUploadFilesModal: false };
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.onShowCustomOfferModal = this.onShowCustomOfferModal.bind(this);
    this.onShowUploadFilesModal = this.onShowUploadFilesModal.bind(this);
    this.onUploadFileDone = this.onUploadFileDone.bind(this);
    this.blurTimeoutId = null;
  }

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  onShowUploadFilesModal(show) {
    this.setState({ showUploadFilesModal: show });
  }

  onUploadFileDone(res) {
    if (res && res.filesUploaded && res.filesUploaded.length > 0) {
      const filesToSave = [];
      for (let i = 0; i < res.filesUploaded.length; i++) {
        const {
          uploadId,
          filename,
          mimetype,
          originalPath,
          source,
          url
        } = res.filesUploaded[i];

        filesToSave.push({
          uploadId,
          filename,
          mimetype,
          originalPath,
          source,
          url
        });
      }

      const { transactionId, isProvider } = this.props;
      saveArtworkFiles({
        files: filesToSave,
        transactionId: transactionId.uuid
      }).then(() => {
        this.props.onLoadArtworkFilesAndMessages({
          id: transactionId.uuid,
          transactionRole: isProvider ? 'provider' : 'customer'
        });
        this.onShowUploadFilesModal(false);
      }).catch(err => {
        console.error(err);
        this.onShowUploadFilesModal(false);
      });
    }
  }

  onShowCustomOfferModal(show) {
    this.setState({ showCustomOfferModal: show });
  }

  onCustomOfferCreated = () => {
    this.onShowCustomOfferModal(false);
    const { onLoadCustomOffersAndMessages, transactionId } = this.props;
    onLoadCustomOffersAndMessages({ id: transactionId.uuid });
  }

  render() {
    return (
      <>
        {this.state.showUploadFilesModal &&
          <PickerOverlay
            apikey={process.env.REACT_APP_FILESTACK_API_KEY}
            pickerOptions={{
              maxFiles: 5,
              onClose: () => {
                this.onShowUploadFilesModal(false);
              }
            }}
            onUploadDone={(res) => this.onUploadFileDone(res)}
          />
        }
        <FinalForm
          {...this.props}
          render={formRenderProps => {
            const {
              rootClassName,
              className,
              messagePlaceholder,
              handleSubmit,
              inProgress,
              sendMessageError,
              invalid,
              form,
              formId,
              onManageDisableScrolling,
              isProvider,
              listing,
              transactionId,
            } = formRenderProps;

            const classes = classNames(rootClassName || css.root, className);
            const submitInProgress = inProgress;
            const submitDisabled = invalid || submitInProgress;
            return (
              <Form className={classes} onSubmit={values => handleSubmit(values, form)}>
                <FieldTextInput
                  inputRootClass={css.textarea}
                  type="textarea"
                  id={formId ? `${formId}.message` : 'message'}
                  name="message"
                  placeholder={messagePlaceholder}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                />
                <div className={css.row}>
                  <div className={isProvider ? css.colUploadButtonProvider : css.colUploadButtonCustomer}>
                    <SecondaryButton
                      rootClassName={css.uploadFilesButton}
                      onClick={() => this.onShowUploadFilesModal(true)}
                    >
                      <FontAwesomeIcon icon={'fa-solid fa-paperclip'} className={css.uploadIcon} />
                    </SecondaryButton>
                  </div>
                  {isProvider &&
                    <div className={css.colCreateOffer}>
                      <SecondaryButton
                        rootClassName={css.createOfferButton}
                        onClick={() => this.onShowCustomOfferModal(true)}
                      >
                        <FormattedMessage id="SendMessageForm.createOffer" />
                      </SecondaryButton>
                    </div>
                  }
                  <div className={isProvider ? css.colSendMessageProvider : css.colSendMessageCustomer}>
                    <SecondaryButton
                      rootClassName={isProvider ? css.submitButtonProvider : css.submitButtonCustomer}
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                    >
                      {isProvider ? (
                        <div class={css.sendMessageTextMobile}>
                          <IconSendMessage />
                        </div>
                      ) : (
                        <div class={css.sendMessageTextMobile}>
                          <IconSendMessage />
                          <FormattedMessage id="SendMessageForm.sendMessage" />
                        </div>
                      )}
                      <div class={css.sendMessageTextDesktop}>
                        <IconSendMessage />
                        <FormattedMessage id="SendMessageForm.sendMessage" />
                      </div>
                    </SecondaryButton>
                  </div>
                </div>
                {sendMessageError ? (
                  <div className={css.row}>
                    <div className={css.col12}>
                      <div className={css.errorContainer}>
                        <p className={css.error}>
                          <FormattedMessage id="SendMessageForm.sendFailed" />
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
                {onManageDisableScrolling && transactionId?.uuid &&
                  <>
                    <Modal
                      id="SendMessageForm.customOfferModal"
                      isOpen={this.state.showCustomOfferModal}
                      onClose={() => this.onShowCustomOfferModal(false)}
                      usePortal={true}
                      onManageDisableScrolling={onManageDisableScrolling}>
                      <CustomOfferForm
                        listing={listing}
                        transactionId={transactionId}
                        onCustomOfferCreated={this.onCustomOfferCreated}
                      />
                    </Modal>
                  </>
                }
              </Form>
            );
          }}
        />
      </>
    );
  }
}

SendMessageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
  transactionId: null,
  isProvider: false,
  listing: null,
};

SendMessageFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,
  transactionId: propTypes.uuid.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  isProvider: bool.isRequired,
  listing: propTypes.listing.isRequired,
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadArtworkFilesAndMessages: params => dispatch(loadArtworkFilesAndMessages(params)),
    onLoadCustomOffersAndMessages: params => dispatch(loadCustomOffersAndMessages(params)),
  };
};

const SendMessageForm = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SendMessageFormComponent);

SendMessageForm.displayName = 'SendMessageForm';

export default SendMessageForm;
