import { FormattedDate, FormattedMessage, injectIntl } from 'react-intl';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import css from './PaymentScheduleBreakdown.module.scss';
import { intlShape } from '../../util/reactIntl';
import { array, object, bool } from 'prop-types';
import dateFormatOptions from './../../util/dateFormatOptions';
import SubscriptionPaymentStatusEnum from '../../enums/subscription-payment-status.enum';

const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

const PaymentScheduleBreakdown = injectIntl(props => {
    const {
        subscriptionPayments, subscriptionTotal, intl,
        isProvider, isAutoRenewal, showPaymentStatus
    } = props;

    if (!subscriptionPayments || !subscriptionTotal) {
        return null;
    }

    const title = isAutoRenewal ? 'Auto-renewal schedule' : 'Payment schedule';

    const formatIntegerToMoney = (amount) => {
        return formatMoney(intl, new Money(amount, config.currency))
    };

    const getPaymentStatusBadge = (status) => {
        let statusBadgeClass = null;
        let statusText = null;
        switch (status) {
            case 1:
                statusBadgeClass = css.paid;
                statusText = intl.formatMessage({ id: 'PaymentScheduleBreakdown.status.paid' });
                break;
            case 2:
                statusBadgeClass = css.scheduled;
                statusText = intl.formatMessage({ id: 'PaymentScheduleBreakdown.status.scheduled' });
                break;
            case 3:
                statusBadgeClass = css.failed;
                statusText = intl.formatMessage({ id: 'PaymentScheduleBreakdown.status.failed' });
                break;
            case 4:
                statusBadgeClass = css.cancelled;
                statusText = intl.formatMessage({ id: 'PaymentScheduleBreakdown.status.cancelled' });
                break;
        };
        return (
            <span className={[css.paymentStatus, statusBadgeClass].join(' ')}>
                {statusText}
            </span>
        );
    };

    const EstimatedPayoutLineItem = ({ providerPayoutLessTaxAmount }) => (
        <div className={css.intervalLabelWrapper}>
            <span><FormattedMessage id="PaymentScheduleBreakdown.totalPayout" /></span>
            <span>
                {formatIntegerToMoney(providerPayoutLessTaxAmount, config.currency)}
            </span>
        </div>
    );

    return (
        <>
            <h2>{title}</h2>
            <h6>
                <FormattedMessage id="PaymentScheduleBreakdown.paymentsInTotalMessage" values={{ count: subscriptionPayments.length }} />
            </h6>
            <br />
            {subscriptionPayments.map(i => {
                return (
                    <div className={css.paymentPeriodContainer}>
                        <div className={css.paymentBreakdown}>
                            {showPaymentStatus && i.paymentStatus &&
                                <div className={css.intervalLabelWrapper}>
                                    <span><FormattedMessage id="PaymentScheduleBreakdown.status" /></span>
                                    {getPaymentStatusBadge(i.paymentStatus)}
                                </div>
                            }
                            <div className={css.intervalLabelWrapper}>
                                <div>
                                    <span>
                                        <FormattedDate value={i.startLabel} {...dateFormatOptions} />
                                    </span>
                                    <span>-</span>
                                    <span>
                                        <FormattedDate value={i.endLabel} {...dateFormatOptions} />
                                    </span>
                                    <span> ({i.daysCountLabel})</span>
                                </div>
                                <span>
                                    {formatIntegerToMoney(i.priceAmount)}
                                </span>
                            </div>
                            {i.discountAmount && i.discountAmount !== 0 ? (
                                <div className={css.intervalLabelWrapper}>
                                    <span><FormattedMessage id="PaymentScheduleBreakdown.discount" /></span>
                                    <span>
                                        {formatIntegerToMoney(i.discountAmount)}
                                    </span>
                                </div>
                            ) : (null)}
                            {i.installationFeeAmount && i.installationFeeAmount !== 0 ? (
                                <div className={css.intervalLabelWrapper}>
                                    <span><FormattedMessage id="PaymentScheduleBreakdown.installationFee" /></span>
                                    <span>
                                        {formatIntegerToMoney(i.installationFeeAmount)}
                                    </span>
                                </div>
                            ) : (null)}
                            <div className={css.intervalLabelWrapper}>
                                <span><FormattedMessage id="PaymentScheduleBreakdown.subtotal" /></span>
                                <span>
                                    {formatIntegerToMoney(i.subtotal)}
                                </span>
                            </div>
                            <div className={css.intervalLabelWrapper}>
                                <span><FormattedMessage id="PaymentScheduleBreakdown.tax" /></span>
                                <span>
                                    {formatIntegerToMoney(i.taxAmount, config.currency)}
                                </span>
                            </div>
                            {!isProvider &&
                                <>
                                    <div className={css.intervalLabelWrapper}>
                                        <span><FormattedMessage id="PaymentScheduleBreakdown.adloFee" /></span>
                                        <span>
                                            {formatIntegerToMoney(i.customerCommissionAmount, config.currency)}
                                        </span>
                                    </div>
                                    <div className={css.intervalLabelWrapper}>
                                        <span><FormattedMessage id="PaymentScheduleBreakdown.taxOnAdloFee" /></span>
                                        <span>
                                            {formatIntegerToMoney(i.taxOnCustomerCommissionAmount, config.currency)}
                                        </span>
                                    </div>
                                    <hr className={css.totalDivider} />
                                    <div className={css.intervalLabelWrapper}>
                                        <span><FormattedMessage id="PaymentScheduleBreakdown.total" /></span>
                                        <span>
                                            {formatIntegerToMoney(i.customerTotalAmount)}
                                        </span>
                                    </div>
                                    {(i.refunded && i.refunded !== 0) ? (
                                        <div className={css.intervalLabelWrapper}>
                                            <span><FormattedMessage id="PaymentScheduleBreakdown.refunded" /></span>
                                            <span>
                                                {formatIntegerToMoney(i.refunded, config.currency)}
                                            </span>
                                        </div>
                                    ) : (null)}
                                </>
                            }
                            {isProvider &&
                                <>
                                    <hr className={css.totalDivider} />
                                    <div className={css.intervalLabelWrapper}>
                                        <span><FormattedMessage id="PaymentScheduleBreakdown.total" /></span>
                                        <span>
                                            {formatIntegerToMoney(i.subtotalPlusTax)}
                                        </span>
                                    </div>
                                </>
                            }
                        </div>
                        {isProvider && (
                            <div className={css.payoutBreakdown}>
                                <h6>Payout breakdown</h6>
                                {(i.paymentStatus !== SubscriptionPaymentStatusEnum.Pending && i.paymentStatus !== SubscriptionPaymentStatusEnum.Failed) ? (
                                    (i.taxOnBookingDeductedFromProviderPayout && i.taxOnBookingDeductedFromProviderPayout !== 0) ? (
                                        <div className={css.intervalLabelWrapper}>
                                            <span><FormattedMessage id="PaymentScheduleBreakdown.taxOnBookingDeductedFromProviderPayout" /></span>
                                            <span>
                                                {formatIntegerToMoney(i.taxOnBookingDeductedFromProviderPayout, config.currency)}
                                            </span>
                                        </div>
                                    ) : (null)
                                ) : (
                                    <div className={css.intervalLabelWrapper}>
                                        <span><FormattedMessage id="PaymentScheduleBreakdown.taxOnBookingDeductedFromProviderPayout" /></span>
                                        <span>
                                            *TBD
                                        </span>
                                    </div>
                                )}
                                {i.providerCommissionAmount &&
                                    <div className={css.intervalLabelWrapper}>
                                        <span><FormattedMessage id="PaymentScheduleBreakdown.adloFee" /></span>
                                        <span>
                                            {formatIntegerToMoney(i.providerCommissionAmount, config.currency)}
                                        </span>
                                    </div>
                                }
                                {i.taxOnProviderCommissionAmount &&
                                    <div className={css.intervalLabelWrapper}>
                                        <span><FormattedMessage id="PaymentScheduleBreakdown.taxOnProviderCommissionAmount" /></span>
                                        <span>
                                            {formatIntegerToMoney(i.taxOnProviderCommissionAmount, config.currency)}
                                        </span>
                                    </div>
                                }
                                {(i.paymentStatus === SubscriptionPaymentStatusEnum.Pending) ? (
                                    <EstimatedPayoutLineItem providerPayoutLessTaxAmount={i.providerPayoutLessTaxAmount} />
                                ) : (
                                    (i.payoutTotal && i.payoutTotal !== 0) ? (
                                        <div className={css.intervalLabelWrapper}>
                                            <span><FormattedMessage id="PaymentScheduleBreakdown.totalPayout" /></span>
                                            <span>
                                                {formatIntegerToMoney(i.payoutTotal, config.currency)}
                                            </span>
                                        </div>
                                    ) : (
                                        <EstimatedPayoutLineItem providerPayoutLessTaxAmount={i.providerPayoutLessTaxAmount} />
                                    )
                                )}
                                {(i.reversed && i.reversed !== 0) ? (
                                    <div className={css.intervalLabelWrapper}>
                                        <span><FormattedMessage id="PaymentScheduleBreakdown.reversed" /></span>
                                        <span>
                                            {formatIntegerToMoney(i.reversed, config.currency)}
                                        </span>
                                    </div>
                                ) : (null)}
                            </div>
                        )}
                        {i.refundNotes &&
                            <div className={css.refundNotes}>
                                <h6>Refund notes</h6>
                                <p>{i.refundNotes}</p>
                            </div>
                        }
                    </div>
                );
            })}
            {/* <div className={css.totalWrapper}>
                <span><FormattedMessage id="PaymentScheduleBreakdown.totalDueLater" /></span>
                <span className={css.totalAmount}>
                    {isProvider ?
                        formatIntegerToMoney(subscriptionTotal?.providerAmount)
                        :
                        formatIntegerToMoney(subscriptionTotal?.customerAmount)
                    }
                </span>
            </div> */}
        </>
    );
});

PaymentScheduleBreakdown.defaultProps = {
    subscriptionPayments: null,
    subscriptionTotal: null,
    isProvider: false,
    intl: null,
};

PaymentScheduleBreakdown.propTypes = {
    subscriptionPayments: array.isRequired,
    subscriptionTotal: object.isRequired,
    isProvider: bool.isRequired,
    intl: intlShape.isRequired,
};

export default PaymentScheduleBreakdown;